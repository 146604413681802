import { useMUD } from "./MUDContext";
import { BrowserRouter } from "react-router-dom";
import GetRouters from "./router"
import "./App.scss"
import './styles/font-family/font.css'
import 'amfe-flexible/index.js'
import { GlobalStateProvider } from "./hook/GlobalStateProvider";
import { configureChains, createConfig, WagmiConfig, Chain } from 'wagmi'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { mainnet } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
export const optimismGoerli = {
    id: 420,
    name: 'Optimism Goerli',
    network: 'Optimism Goerli',
    nativeCurrency: {
        decimals: 18,
        name: 'Goerli Ether',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['https://optimism-goerli.blockpi.network/v1/rpc/public'] },
        default: { http: ['https://optimism-goerli.blockpi.network/v1/rpc/public'] },
    },
    blockExplorers: {
        etherscan: { name: 'Etherscan', url: 'https://goerli-optimism.etherscan.io' },
        default: { name: 'Etherscan', url: 'https://goerli-optimism.etherscan.io' },
    }
} as const satisfies Chain
export const redstone = {
    id: 17001,
    name: 'Redstone Holesky',
    network: 'Redstone Holesky',
    nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
    },
    rpcUrls: {
        public: { http: ['https://rpc.holesky.redstone.xyz'] },
        default: { http: ['https://rpc.holesky.redstone.xyz'] },
    },
    blockExplorers: {
        etherscan: { name: 'Etherscan', url: 'https://explorer.holesky.redstone.xyz' },
        default: { name: 'Etherscan', url: 'https://explorer.holesky.redstone.xyz' },
    }
} as const satisfies Chain
export const opBNB = {
    id: 5611,
    name: 'opBNB Testnet',
    network: 'opBNB Testnet',
    nativeCurrency: {
        decimals: 18,
        name: 'tBNB',
        symbol: 'tBNB',
    },
    rpcUrls: {
        public: { http: ['https://opbnb-testnet-rpc.bnbchain.org'] },
        default: { http: ['https://opbnb-testnet-rpc.bnbchain.org'] },
    },
    blockExplorers: {
        etherscan: { name: 'BscScan', url: 'https://opbnb-testnet.bscscan.com' },
        default: { name: 'BscScan', url: 'https://opbnb-testnet.bscscan.com' },
    }
} as const satisfies Chain
export const Artela = {
    id: 11822,
    name: 'Artela Testnet',
    network: 'Artela Testnet',
    nativeCurrency: {
        decimals: 18,
        name: 'ART',
        symbol: 'ART',
    },
    rpcUrls: {
        public: {http: ['https://betanet-rpc1.artela.network/']},
        default: {http: ['https://betanet-rpc1.artela.network/']},
    },
    blockExplorers: {
        etherscan: {name: 'BscScan', url: 'https://betanet-scan.artela.network/'},
        default: {name: 'BscScan', url: 'https://betanet-scan.artela.network/'},
    }
} as const satisfies Chain
export const App = () => {
  const {
    components: { Counter },
    systemCalls: { increment },
  } = useMUD();
    const projectId = '8b3fa7c95ab1c2257bfe4a9cd850ca57'

    const { chains, publicClient } = configureChains(
        [Artela, redstone],
        [walletConnectProvider({ projectId }), publicProvider()]
    )
    const metadata = {
        name: 'Web3Modal',
        description: 'Web3Modal Example',
        url: 'https://web3modal.com',
        icons: ['https://avatars.githubusercontent.com/u/37784886']
    }
    const wagmiConfig = createConfig({
        autoConnect: true,
        connectors: [
            new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
            new EIP6963Connector({ chains }),
            new InjectedConnector({ chains, options: { shimDisconnect: true } }),
        ],
        publicClient
    })
    createWeb3Modal({wagmiConfig, projectId, chains, defaultChain: Artela})
  return (
      <>
          <GlobalStateProvider>
              <WagmiConfig config={wagmiConfig}>
                  <div>
                      <BrowserRouter>
                          <GetRouters />
                      </BrowserRouter>
                  </div>
              </WagmiConfig>
          </GlobalStateProvider>
      </>
  );
};
