import request from '../utils/request'

const URL = {
    EXIT: 'pvp/exit',
    JOIN: 'pvp/join',
    CREATE_ROOM: 'pvp/pvpCreateRoom',
    GET_ROOMS: 'pvp/rooms',
    NEXT_STEP: 'pvp/nextStep',
    GET_GAME: 'pvp/getGame',
    GAME_INFO: 'pvp/getGameInfo',
    GET_PLAYER: 'pvp/playerRank',
    GET_LIFE_RANK: 'pvp/lifeRank'
}

export default {
    exitRoom(formData: any){
        return request.post(URL.EXIT, formData)
    },
    joinRoom(formData: any) {
        return request.post(URL.JOIN, formData)
    },
    createRoom(formData: any) {
        return request.post(URL.CREATE_ROOM, formData)
    },
    getRooms(address: any, pageSize: any, pageNum: any) {
        return request.get(URL.GET_ROOMS, {
            params: {
                ethAddress: address,
                pageNum: pageNum,
                pageSize: pageSize
            }
        })
    },
    nextStep(id: string) {
        return request.get(URL.NEXT_STEP, {
            params: {
                pvpGameId: id
            }
        })
    },
    getGame(ethAddress: any) {
        return request.get(URL.GET_GAME, {
            params: {
                ethAddress: ethAddress
            }
        })
    },
    getGameInfo(roomId: any) {
        return request.get(URL.GAME_INFO, {
            params: {
                roomId: roomId
            }
        })
    },
    getLifeRank() {
        return request.get(URL.GET_LIFE_RANK)
    },
    getPlayerRank(address: string) {
        return request.get(URL.GET_PLAYER, {
            params: {
                ethAddress: address
            }
        })
    }
}