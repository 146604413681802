
      // Import the functions you need from the SDKs you need
      import { initializeApp } from "https://www.gstatic.com/firebasejs/10.5.0/firebase-app.js";
      import { getAnalytics } from "https://www.gstatic.com/firebasejs/10.5.0/firebase-analytics.js";
      // TODO: Add SDKs for Firebase products that you want to use
      // https://firebase.google.com/docs/web/setup#available-libraries

      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      const firebaseConfig = {
        apiKey: "AIzaSyCjGhrB3CUA1j09ORlwToNmFsgt5InszZU",
        authDomain: "pac-man-5f2c0.firebaseapp.com",
        projectId: "pac-man-5f2c0",
        storageBucket: "pac-man-5f2c0.appspot.com",
        messagingSenderId: "548998850634",
        appId: "1:548998850634:web:f0c606413a72ff08505eb3",
        measurementId: "G-5173DHVXH2"
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    