import "./index.scss"
import Button from "../../components/Button";
import { getSrc } from "../../utils/utils";
import { Modal } from "antd";
import {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PVPNftItem } from "./nftItem";
import {useAccount} from "wagmi";
import {Pvp} from "../../api";
import {Helmet} from "react-helmet";
import {useGlobalState} from "../../hook/useGlobalState";

export function CreateLobby() {
  const navigate = useNavigate()
  const { tokenId } = useParams()
  const [modalVisible, setModalVisible] = useState(false)
  const backPage = () => {
    navigate(-1)
  }
    const {setRoomId} = useGlobalState()
  const [nftDetail, setNftDetail] = useState({})
  const { address } = useAccount()
    useEffect(() => {
        if (address) {
            Pvp.getGame(address).then((res: any) => {
                if (res.code === 200) {
                    setNftDetail(res.data.roomInfo)
                }
            })
            repeatData()
        }
    }, [address]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            repeatData();
        }, 2000);

        // 返回的函数将在组件卸载时调用，清除定时器
        return () => clearInterval(intervalId);
    }, []);
  const [gameId, setGameId] = useState(null as any)
  const repeatData = () => {
      Pvp.getGame(address).then((res: any) => {
          if (res.code === 200) {
              setGameId(res.data.roomId)
              if (res.data.gameStatus) {
                  navigate(`/game-pvp/${tokenId}`)
              }
          }
      })
  }
  const exitRoom = () => {
      if (gameId) {
          const formData = {
              ethAddress: address,
              gameId: gameId
          }
          Pvp.exitRoom(formData).then((res: any) => {
              if (res.code === 200) {
                  setRoomId(0)
                  navigate(-1)
              }
          })
      }
  }
  return (
    <div className={"create-lobby-container"}>
        <Helmet><title>Create Room</title></Helmet>
        <div className={"lobby-main"}>
        <div className={"lobby-header"}>
            <img onClick={() => navigate(-1)} className={"arrow-icon"} src={getSrc('setout/arrow-left.png')} alt=""/>
        </div>
        <div className={"create-container"}>
          <div className={"create-box"}>
            <PVPNftItem nftDetail={nftDetail}></PVPNftItem>
              <div className={"vs-box"}>
                  <img className={"vs-icon"} src={getSrc('lobby/vs.png')} alt="" />
              </div>
            <PVPNftItem isData={true}></PVPNftItem>
          </div>
          <div className={"footer-button"}>
            <Button>
              <div onClick={() => setModalVisible(true)}>
                Exit waiting
              </div>
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={modalVisible}
        className={"create-modal-container"}
        getContainer={false}
        closeIcon={false}
        footer={[
          <Button small={true} key="back">
            <div onClick={exitRoom}>
                Confirm
            </div>
          </Button>,
          <Button small={true} key="Confirm">
            <div onClick={() => setModalVisible(false)}>
                Cancel
            </div>
          </Button>
        ]}
      >
        <div className="create-modal">
          <img className={"create-lobby-background"} src={getSrc('lobby/create-lobby.png')} alt="" />
        </div>
      </Modal>
    </div>
  )
}