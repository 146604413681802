import "./NavButton.scss"
import { getSrc } from "../utils/utils"
import React from "react";

type ButtonProps = {
    children: React.ReactNode,
    disabled?: boolean,
    isLoading?: boolean,
    onClick?: (...args: any[]) => void
}

const ActivitiesButton: React.FC<ButtonProps> = ({ children, disabled = false, isLoading = false, onClick }) => {
    const buttonClass = disabled ? 'activities-button button-disabled' : 'activities-button';
    return <div className={buttonClass} onClick={onClick}>
        <div className={"button-content"}>
            {children}
        </div>
    </div>;
};

export default ActivitiesButton;