import "./game.scss";
import {getSrc, findCharacters, clickMusic, useCountdownSeconds} from "../../utils/utils";
import Button from "../../components/Button";
import {useNavigate, useParams} from "react-router-dom";
import {useAccount, useContractWrite} from "wagmi";
import {useEffect, useRef, useState} from "react";
import {Pvp, User} from "../../api";
import {detectMovement, findDisappear} from "../../utils/game";
import Attack from "../../assets/musics/attack.wav";
import Gold from "../../assets/musics/gold.wav";
import {DragDropContext, Draggable, Draggable as DraggableRBdnd, Droppable} from "react-beautiful-dnd";
import {Modal, Select} from "antd";
import {wagmigotchiContract} from "../../utils/contract";
import {useGlobalState} from "../../hook/useGlobalState";
import {Helmet} from "react-helmet";
import Typed from "react-typed";

const condition: any = [
  {value: 'A', label: 'My Health ≥ 50% & Encounter enemy'},
  {value: 'B', label: 'My Health < 50% & Encounter enemy'},
  {value: 'C', label: 'Score Gap > 20 & Encounter enemy'},
  {value: 'D', label: 'Score Gap < 20 & Encounter enemy'},
  {value: 'G', label: 'Discover prop'},
]
const action: any = {
  'A': [{value: '1', label: 'Runaway'},
    {value: '2', label: 'Move towards coins'},
    {value: '3', label: 'Attack'}],
  'B': [{value: '1', label: 'Runaway'},
    {value: '2', label: 'Move towards coins'},
    {value: '3', label: 'Attack'}],
  'C': [{value: '1', label: 'Runaway'},
    {value: '2', label: 'Move towards coins'},
    {value: '3', label: 'Attack'}],
  'D': [{value: '1', label: 'Runaway'},
    {value: '2', label: 'Move towards coins'},
    {value: '3', label: 'Attack'}],
  'G': [
    {value: '4', label: 'Move towards prop'},
    {value: '5', label: 'Reverse movement'},
  ]
}

interface GameProps {
  reloadComponent: () => void;
}

const Game: React.FC<GameProps> = ({reloadComponent}) => {
  const { address } = useAccount()
  const [roomId, setRoomId] = useState(null as any)
  const [tokenId, setTokenId] = useState(null as any)
  const { id } = useParams()
  const navigate = useNavigate();
  const backPage = () => {
    navigate(-1)
  };
  useEffect(() => {
    if (address) {
      Pvp.getGame(address).then((res: any) => {
        if (res.code === 200) {
          setRoomId(res.data.roomId)
          setTokenId(res.data.tokenId)
        }
      })
    }
  }, [address]);
  const [gameMap, setGameMap] = useState([] as any)
  useEffect(() => {
    if (roomId) {
      connectWss()
      // Pvp.getGameInfo(roomId).then((res: any) => {
      //   if (res.code === 200) {
      //     if (res.data.dlwLeftRounds) {
      //       setBoosterPill(parseInt(res.data.dlwLeftRounds))
      //     }
      //     if (res.data.vertigoLeftRounds) {
      //       setVertigoPill(parseInt(res.data.vertigoLeftRounds))
      //     }
      //     setMineIndex(res.data.pvpLifeInfos.findIndex((item: any) => item.tokenId === tokenId))
      //     setGameMap(res.data.gameMap)
      //     setGameInfo(res.data)
      //     if (res.data.gameRound) {
      //       setGameRound(res.data.gameRound)
      //     }
      //   }
      // })
    }
  }, [roomId]);
  const [rulesLog, setRulesLog] = useState([] as any)
  const [gameInfo, setGameInfo] = useState(null as any)
  const [ghostInfo, setGhostInfo] = useState([] as any)
  const [oldGhostInfo, setOldGhostInfo] = useState([] as any)
  useEffect(() => {
    if (gameInfo) {
      firstDataRef.current = gameInfo
      setGhostInfo(gameInfo.ghostInfos)
      gameInfo.pvpLifeInfos.forEach((item: any) => {
        if (item.eatD) {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-big')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-big');
            }
          })
        }
        if (item.dizziness) {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-stun')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-stun');
            }
          })
        }
      })
      gameInfo.ghostInfos.forEach((item: any, index: number) => {
        const mapBlocks = document.getElementsByClassName('map-block');
        if (mapBlocks.length > 0 && item.ghostArrange && item.ghostLine) {
          const elementBlock: any = document.querySelector('.block');
          const rect = elementBlock.getBoundingClientRect();
          const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
          const remValue = rect.width / rootFontSize;
          const firstMapBlock = mapBlocks[0];
          const newDiv = document.createElement('div');
          newDiv.className = `block block-0-${item.ghostType}`;
          newDiv.style.left = `${item.ghostArrange*remValue+0.13}rem`;
          newDiv.style.top = `${item.ghostLine*remValue}rem`;
          firstMapBlock.appendChild(newDiv);
        }
      })
      gameInfo.ghostInfos.forEach((item: any) => {
        if (item.eatD) {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-big')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-big');
            }
          })
        }
        if (item.dizziness) {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-stun')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-stun');
            }
          })
        }
      })
      if (!oldGhostInfo.length) {
        setOldGhostInfo(gameInfo.ghostInfos)
      }
      // getNextStep()
      continueExecution(gameInfo)
      // connectWss()
    }
  }, [gameInfo]);
  const [nextStep, setNextStep] = useState({} as any)
  const [ghostHealths, setGhostHealths] = useState([]);
  const [previousGhostHealths, setPreviousGhostHealths] = useState([]);

  useEffect(() => {
    ghostHealths.forEach((health, index) => {
      if (health === 0 && nextStep.ghostInfos) {
        const element: any = document.getElementsByClassName(`block-0-${nextStep.ghostInfos[index].ghostType}`);
        if (element && element[0]) {
          element[0].style.display = 'none';
        }
      }
    });
    ghostInfo.forEach((info: any, index: number) => {
      if (ghostInfo[index]?.fighting === '1') {
        const typeName = ghostInfo[index].ghostType
        const indexGhost = nextStep.ghostFaceToward.findIndex((item: any) => item.type === typeName);
        if (indexGhost !== -1) {
          const elements = document.querySelectorAll(`.map-container .block-0-${nextStep.ghostFaceToward[indexGhost].type}`)
          elements.forEach((element: any) => {
            if (element) {
              if (nextStep.ghostFaceToward[indexGhost].turn === 'UP') {
                element.classList.add('moveUpAnimationPvP')
                element.addEventListener('animationend', function() {
                  element.classList.remove('moveUpAnimationPvP');
                });
              } else if (nextStep.ghostFaceToward[indexGhost].turn === 'DOWN') {
                element.classList.add('moveDownAnimationPvP')
                element.addEventListener('animationend', function() {
                  element.classList.remove('moveDownAnimationPvP');
                });
              } else if (nextStep.ghostFaceToward[indexGhost].turn === 'LEFT') {
                element.classList.add('moveLeftAnimationPvP')
                element.addEventListener('animationend', function() {
                  element.classList.remove('moveLeftAnimationPvP');
                });
              } else if (nextStep.ghostFaceToward[indexGhost].turn === 'RIGHT') {
                element.classList.add('moveRightAnimationPvP')
                element.addEventListener('animationend', function() {
                  element.classList.remove('moveRightAnimationPvP');
                });
              }
            }
          });
        }
      }
    })
    setPreviousGhostHealths(ghostHealths);
  }, [ghostHealths]);
  function checkString(str: string) {
    if (str.includes('3')) return 3;
    if (str.includes('4')) return 4;
    if (str.includes('5')) return 5;
    if (str.includes('6')) return 6;
    return 'No match found';
  }
  useEffect(() => {
    if (ghostInfo.length && oldGhostInfo.length && nextStep.gameRound) {
      ghostInfo.forEach((item: any, index: number) => {
        const newArr: any = [item.ghostArrange,item.ghostLine]
        const oldArr: any = [oldGhostInfo[index].ghostArrange,oldGhostInfo[index].ghostLine]
        const move = detectMovement(oldArr, newArr)
        const element: any = document.getElementsByClassName(`block-0-${item.ghostType}`)
        if (element) {
          const typeName = item.ghostType
          const indexGhost = nextStep.ghostFaceToward.findIndex((item: any) => item.type === typeName);
          if (indexGhost !== -1) {
            element[0].style.backgroundImage = `url("https://img.cellula.life/rankback/pc/${checkString(typeName)}-${nextStep.ghostFaceToward[indexGhost].turn}.png")`
          }
          const elementBlock: any = document.querySelector('.block');
          const rect = elementBlock.getBoundingClientRect();
          const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
          const remValue = rect.width / rootFontSize;
          if (move === 'UP') {
            element[0].style.top = parseFloat(element[0].style.top) ? parseFloat(element[0].style.top) - remValue + 'rem' : - remValue + 'rem'
          } else if (move === 'DOWN') {
            element[0].style.top = parseFloat(element[0].style.top) ? parseFloat(element[0].style.top) + remValue + 'rem' : + remValue + 'rem'
          } else if (move === 'LEFT') {
            element[0].style.left = parseFloat(element[0].style.left) ? parseFloat(element[0].style.left) - remValue + 'rem' : - remValue + 'rem'
          } else if (move === 'RIGHT') {
            element[0].style.left = parseFloat(element[0].style.left) ? parseFloat(element[0].style.left) + remValue + 'rem' : + remValue + 'rem'
          }
        }
      })
      setOldGhostInfo(ghostInfo)
    }
  }, [ghostInfo]);
  const [mineIndex, setMineIndex] = useState(null as any)
  const getPosition = async ()  => {
    const elements = document.querySelectorAll(`.map-container .block-${nextStep.pvpLifeInfos[mineIndex].typeName}`)
    elements.forEach((element: any) => {
      if (element) {
        const elementBlock: any = document.querySelector('.block');
        const rect = elementBlock.getBoundingClientRect();
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const remValue = rect.width / rootFontSize;
        if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward) {
          if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'UP') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b-top.png")'
          } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'DOWN') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b.png")'
          } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'LEFT') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b-left.png")'
          } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'RIGHT') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b-right.png")'
          }
        }
        if (nextStep.pvpLifeInfos[mineIndex].toward === 'UP') {
          element.style.top = parseFloat(element.style.top) ? parseFloat(element.style.top) - remValue + 'rem' : - remValue + 'rem'
        } else if (nextStep.pvpLifeInfos[mineIndex].toward === 'DOWN') {
          element.style.top = parseFloat(element.style.top) ? parseFloat(element.style.top) + remValue + 'rem' : + remValue + 'rem'
        } else if (nextStep.pvpLifeInfos[mineIndex].toward === 'LEFT') {
          element.style.left = parseFloat(element.style.left) ? parseFloat(element.style.left) - remValue + 'rem' : - remValue + 'rem'
        } else if (nextStep.pvpLifeInfos[mineIndex].toward === 'RIGHT') {
          element.style.left = parseFloat(element.style.left) ? parseFloat(element.style.left) + remValue + 'rem' : + remValue + 'rem'
        }
        if (nextStep.pvpLifeInfos[mineIndex].health === 0) {
          // element.style.display = 'none'
          element.style.opacity = '0'
        }
      }
    });
    const elements2 = document.querySelectorAll(`.map-container .block-${nextStep.pvpLifeInfos[otherIndex]?.typeName}`)
    elements2.forEach((element: any) => {
      if (element) {
        const elementBlock: any = document.querySelector('.block');
        const rect = elementBlock.getBoundingClientRect();
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const remValue = rect.width / rootFontSize;
        if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward) {
          if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'UP') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-top.png")'
          } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'DOWN') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user.png")'
          } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'LEFT') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-left.png")'
          } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'RIGHT') {
            element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-right.png")'
          }
        }
        if (nextStep.pvpLifeInfos[otherIndex].toward === 'UP') {
          element.style.top = parseFloat(element.style.top) ? parseFloat(element.style.top) - remValue + 'rem' : - remValue + 'rem'
        } else if (nextStep.pvpLifeInfos[otherIndex].toward === 'DOWN') {
          element.style.top = parseFloat(element.style.top) ? parseFloat(element.style.top) + remValue + 'rem' : + remValue + 'rem'
        } else if (nextStep.pvpLifeInfos[otherIndex].toward === 'LEFT') {
          element.style.left = parseFloat(element.style.left) ? parseFloat(element.style.left) - remValue + 'rem' : - remValue + 'rem'
        } else if (nextStep.pvpLifeInfos[otherIndex].toward === 'RIGHT') {
          element.style.left = parseFloat(element.style.left) ? parseFloat(element.style.left) + remValue + 'rem' : + remValue + 'rem'
        }
        if (nextStep.pvpLifeInfos[otherIndex].health === 0) {
          // element.style.display = 'none'
          element.style.opacity = '0'
        }
      }
    });
    return true
  }
  const [submitVisible, setSubmitVisible] = useState(false)
  const [isClearance, setIsClearance] = useState(false)
  const [endStatus, setEndStatus] = useState(0)

  const {setExitGame, isNetwork, setIsMask} = useGlobalState()
  const {count, countdown} = useCountdownSeconds()

  const {isSuccess, write, isLoading} = useContractWrite({
    ...wagmigotchiContract,
    functionName: 'saveLifeInfo'
  })
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        // submit bill
        User.payBill(address).then((res: any) => {
          if (res.code === 200) {
            setSubmitVisible(false)
            setEndVisible(true)
          }
        })
        setSubmitVisible(false)
        setEndVisible(true)
      }, 5000)
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isLoading) {
      countdown(60)
    } else {
      setTimeout(() => {
        countdown(0)
      }, 5000)
    }
  }, [isLoading]);
  const submitContract = () => {
    if (tokenId && isNetwork) {
      clickMusic()
      write({args: [parseInt(tokenId), viewRange, attack, options.join(',')]})
    }
  }
  const [otherIndex, setOtherIndex] = useState(null as any)
  const [mineHealth, setMineHealth] = useState(0)
  const [mineTotalHealth, setMineTotalHealth] = useState(0)
  const [otherHealth, setOtherHealth] = useState(0)
  const [otherTotalHealth, setOtherTotalHealth] = useState(0)
  const [boosterPill, setBoosterPill] = useState(0)
  const [vertigoPill, setVertigoPill] = useState(0)
  useEffect(() => {
    if (mineIndex !== null) {
      if (gameInfo?.hitRules?.length) {
        setRulesLog(gameInfo?.hitRules.filter((item: any) => {return item.tokenId === tokenId && item.ruleName}))
      }
      setActions(gameInfo?.pvpLifeInfos[mineIndex].moveRule.split(','))
      if (!options.length) {
        setOptions(gameInfo?.pvpLifeInfos[mineIndex].moveRule.split(','))
      }
      setAttack(gameInfo?.pvpLifeInfos[mineIndex].attack)
      setCoinPower(gameInfo?.pvpLifeInfos[mineIndex].coinPower)
      setViewRange(gameInfo?.pvpLifeInfos[mineIndex].viewRange)
      if (mineIndex) {
        setOtherIndex(0)
      } else {
        setOtherIndex(1)
      }
    }
  }, [mineIndex]);
  useEffect(() => {
    if (otherIndex !== null && !nextStep.pvpLifeInfos) {
      const mineDom: any = document.getElementsByClassName(`block-${gameInfo.pvpLifeInfos[mineIndex].typeName}`)
      const otherDom: any = document.getElementsByClassName(`block-${gameInfo.pvpLifeInfos[otherIndex].typeName}`)
      if (mineDom.length) {
        mineDom[0].style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b.png")'
        mineDom[0].classList.add('mine-avatar')
      }
      if (otherDom.length) {
        otherDom[0].style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user.png")'
      }
      setMineInfo(gameInfo.pvpLifeInfos[mineIndex])
      setOtherInfo(gameInfo.pvpLifeInfos[otherIndex])
      setMineHealth(gameInfo.pvpLifeInfos[mineIndex].health)
      setMineTotalHealth(gameInfo.pvpLifeInfos[mineIndex].totalHealth)
      setOtherHealth(gameInfo.pvpLifeInfos[otherIndex].health)
      setOtherTotalHealth(gameInfo.pvpLifeInfos[otherIndex].totalHealth)
    }
  }, [otherIndex]);
  // useEffect(() => {
  //   if (mineHealth && mineHealth !== mineTotalHealth && nextStep.pvpLifeInfos) {
  //     const elements = document.querySelectorAll(`.map-container .block-${nextStep.pvpLifeInfos[mineIndex].typeName}`)
  //     elements.forEach((element: any) => {
  //       if (element) {
  //         const audioAttack = new Audio(Attack);
  //         audioAttack.play()
  //         if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'UP') {
  //           element.classList.add('moveUpAnimationPvP')
  //           element.classList.add('attack-animate-top')
  //           element.addEventListener('animationend', function() {
  //             element.classList.remove('moveUpAnimationPvP');
  //             element.classList.remove('attack-animate-top');
  //           });
  //         } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'DOWN') {
  //           element.classList.add('moveDownAnimationPvP')
  //           element.classList.add('attack-animate-bottom')
  //           element.addEventListener('animationend', function() {
  //             element.classList.remove('moveDownAnimationPvP');
  //             element.classList.remove('attack-animate-bottom');
  //           });
  //         } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'LEFT') {
  //           element.classList.add('moveLeftAnimationPvP')
  //           element.classList.add('attack-animate-left')
  //           element.addEventListener('animationend', function() {
  //             element.classList.remove('moveLeftAnimationPvP');
  //             element.classList.remove('attack-animate-left');
  //           });
  //         } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'RIGHT') {
  //           element.classList.add('moveRightAnimationPvP')
  //           element.classList.add('attack-animate-right')
  //           element.addEventListener('animationend', function() {
  //             element.classList.remove('moveRightAnimationPvP');
  //             element.classList.remove('attack-animate-right');
  //           });
  //         }
  //       }
  //     });
  //   }
  // }, [mineHealth]);
  useEffect(() => {
    if (otherHealth && otherHealth !== otherTotalHealth && nextStep.pvpLifeInfos) {
      const elements = document.querySelectorAll(`.map-container .block-${nextStep.pvpLifeInfos[otherIndex].typeName}`)
      // elements.forEach((element: any) => {
      //   if (element) {
      //     const audioAttack = new Audio(Attack);
      //     audioAttack.play()
      //     if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'UP') {
      //       element.classList.add('moveUpAnimationPvP')
      //       element.classList.add('attack-animate-top')
      //       element.addEventListener('animationend', function() {
      //         element.classList.remove('moveUpAnimationPvP');
      //         element.classList.remove('attack-animate-top');
      //       });
      //     } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'DOWN') {
      //       element.classList.add('moveDownAnimationPvP')
      //       element.classList.add('attack-animate-bottom')
      //       element.addEventListener('animationend', function() {
      //         element.classList.remove('moveDownAnimationPvP');
      //         element.classList.remove('attack-animate-bottom');
      //       });
      //     } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'LEFT') {
      //       element.classList.add('moveLeftAnimationPvP')
      //       element.classList.add('attack-animate-left')
      //       element.addEventListener('animationend', function() {
      //         element.classList.remove('moveLeftAnimationPvP');
      //         element.classList.remove('attack-animate-left');
      //       });
      //     } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'RIGHT') {
      //       element.classList.add('moveRightAnimationPvP')
      //       element.classList.add('attack-animate-right')
      //       element.addEventListener('animationend', function() {
      //         element.classList.remove('moveRightAnimationPvP');
      //         element.classList.remove('attack-animate-right');
      //       });
      //     }
      //   }
      // });
    }
  }, [otherHealth]);
  const mineAttack = () => {
    const elements = document.querySelectorAll(`.map-container .block-${nextStep.pvpLifeInfos[mineIndex].typeName}`)
    elements.forEach((element: any) => {
      if (element) {
        const audioAttack = new Audio(Attack);
        audioAttack.play()
        if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'UP') {
          element.classList.add('moveUpAnimationPvP')
          element.classList.add('attack-animate-top')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveUpAnimationPvP');
            element.classList.remove('attack-animate-top');
          });
        } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'DOWN') {
          element.classList.add('moveDownAnimationPvP')
          element.classList.add('attack-animate-bottom')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveDownAnimationPvP');
            element.classList.remove('attack-animate-bottom');
          });
        } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'LEFT') {
          element.classList.add('moveLeftAnimationPvP')
          element.classList.add('attack-animate-left')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveLeftAnimationPvP');
            element.classList.remove('attack-animate-left');
          });
        } else if (nextStep.pvpLifeInfos[mineIndex].lifeFaceToward === 'RIGHT') {
          element.classList.add('moveRightAnimationPvP')
          element.classList.add('attack-animate-right')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveRightAnimationPvP');
            element.classList.remove('attack-animate-right');
          });
        }
      }
    });
  }
  const otherAttack = () => {
    const elements = document.querySelectorAll(`.map-container .block-${nextStep.pvpLifeInfos[otherIndex].typeName}`)
    elements.forEach((element: any) => {
      if (element) {
        const audioAttack = new Audio(Attack);
        audioAttack.play()
        if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'UP') {
          element.classList.add('moveUpAnimationPvP')
          element.classList.add('attack-animate-top')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveUpAnimationPvP');
            element.classList.remove('attack-animate-top');
          });
        } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'DOWN') {
          element.classList.add('moveDownAnimationPvP')
          element.classList.add('attack-animate-bottom')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveDownAnimationPvP');
            element.classList.remove('attack-animate-bottom');
          });
        } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'LEFT') {
          element.classList.add('moveLeftAnimationPvP')
          element.classList.add('attack-animate-left')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveLeftAnimationPvP');
            element.classList.remove('attack-animate-left');
          });
        } else if (nextStep.pvpLifeInfos[otherIndex].lifeFaceToward === 'RIGHT') {
          element.classList.add('moveRightAnimationPvP')
          element.classList.add('attack-animate-right')
          element.addEventListener('animationend', function() {
            element.classList.remove('moveRightAnimationPvP');
            element.classList.remove('attack-animate-right');
          });
        }
      }
    });
  }
  useEffect(() => {
    if (nextStep && nextStep.gameRound) {
      nextStepRef.current = nextStep
      nextStep.pvpLifeInfos.forEach((item: any) => {
        if (item.eatD) {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-big')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-big');
            }
          })
        }
        if (item.dizziness) {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-stun')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-${item.typeName}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-stun');
            }
          })
        }
      })
      nextStep.ghostInfos.forEach((item: any) => {
        if (item.eatD) {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-big')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-big');
            }
          })
        }
        if (item.dizziness) {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.add('avatar-stun')
            }
          })
        } else {
          const elements = document.querySelectorAll(`.map-container .block-0-${item.ghostType}`)
          elements.forEach((element) => {
            if (element) {
              element.classList.remove('avatar-stun');
            }
          })
        }
      })
      if (nextStep.pvpLifeInfos[mineIndex]?.fighting === '1') {
        mineAttack()
      }
      if (nextStep.pvpLifeInfos[otherIndex]?.fighting === '1') {
        otherAttack()
      }
      setActions(nextStep.pvpLifeInfos[mineIndex].moveRule.split(','))
      if (!options.length) {
        setOptions(nextStep.pvpLifeInfos[mineIndex].moveRule.split(','))
      }
      if (!attack) {
        setAttack(nextStep.pvpLifeInfos[mineIndex].attack)
        setCoinPower(nextStep.pvpLifeInfos[mineIndex].coinPower)
        setViewRange(nextStep.pvpLifeInfos[mineIndex].viewRange)
      }
      getPosition().then(async (res: any) => {
        setMineInfo(nextStep.pvpLifeInfos[mineIndex])
        setOtherInfo(nextStep.pvpLifeInfos[otherIndex])
        setMineHealth(nextStep.pvpLifeInfos[mineIndex]?.health)
        setOtherHealth(nextStep.pvpLifeInfos[otherIndex]?.health)
        setTimeout(async () => {
          // getNextStep();
        }, 333);
      });
    }
  }, [nextStep]);
  const [mineInfo, setMineInfo] = useState(null as any)
  const [otherInfo, setOtherInfo] = useState(null as any)
  const [gameRound, setGameRound] = useState(0)
  const [round, setRound] = useState('360' as any)
  useEffect(() => {
    if (gameRound) {
      const rounds = (360-gameRound).toString()
      setRound(rounds.toString().padStart(3, '0'));
    }
  }, [gameRound]);
  // wss
  const [ws, setWs] = useState<WebSocket | null>(null);
  const nextStepRef = useRef(nextStep as any)
  const gameMapRef = useRef(gameMap as any)
  const rulesLogRef = useRef(rulesLog as any)
  useEffect(() => {
    if (gameMap.length) {
      gameMapRef.current = gameMap
    }
  }, [gameMap]);
  useEffect(() => {
    if (rulesLog.length) {
      rulesLogRef.current = rulesLog
    }
  }, [rulesLog]);
  const updateGameInfo = (res: any) => {
    if (res.data?.gameRound) {
      if (res.data.dlwLeftRounds) {
        setBoosterPill(parseInt(res.data.dlwLeftRounds))
      }
      if (res.data.vertigoLeftRounds) {
        setVertigoPill(parseInt(res.data.vertigoLeftRounds))
      }
      setMineIndex(res.data.pvpLifeInfos.findIndex((item: any) => item.tokenId === tokenId))
      setGameMap(res.data.gameMap)
      setGameInfo(res.data)
      if (res.data.gameRound) {
        setGameRound(res.data.gameRound)
      }
    }
  }
  const firstDataRef = useRef(gameInfo as any)
  const continueExecution = (res: any) => {
    if (res.data?.gameRound) {
      if (res.data.dlwLeftRounds) {
        setBoosterPill(parseInt(res.data.dlwLeftRounds))
      }
      if (res.data.vertigoLeftRounds) {
        setVertigoPill(parseInt(res.data.vertigoLeftRounds))
      }
      const coinsIndex = findDisappear(nextStepRef.current?.gameMap ? nextStepRef.current?.gameMap : firstDataRef.current?.gameMap, res.data.gameMap)
      const newGameMap = [...gameMapRef.current]
      coinsIndex.forEach((item: any, index: number) => {
        newGameMap[item.i] = [...newGameMap[item.i]]
        newGameMap[item.i][item.j] = '0'
        const audioGold = new Audio(Gold);
        audioGold.play()
      })
      const newRules = [...rulesLogRef.current]
      if (res.data.hitRules.filter((item: any) => {return item.tokenId === tokenId && item.ruleName})) {
        newRules.unshift(...res.data.hitRules.filter((item: any) => {return item.tokenId === tokenId && item.ruleName}))
      }
      setRulesLog(newRules.slice(0,6))
      const positions = findCharacters(res.data.gameMap, ['d', 'v']);
      positions.forEach((item: any, index: number) => {
        newGameMap[item.i] = [...newGameMap[item.i]]
        newGameMap[item.i][item.j] = item.name
        if (item.name === 'd' || item.name === 'v') {
          setTimeout(() => {
            const domD = document.querySelectorAll('.map-container .block-d')
            const domV = document.querySelectorAll('.map-container .block-v')
            domD.forEach((item: any) => {
              if (item) {
                item.classList.add('block-prop-anim')
              }
            })
            domV.forEach((item: any) => {
              if (item) {
                item.classList.add('block-prop-anim')
              }
            })
          }, 200)
        }
      })
      setTimeout(() => {
        setGameMap(newGameMap)
      }, 200)
      setMineIndex(res.data.pvpLifeInfos.findIndex((item: any) => item.tokenId === tokenId))
      setNextStep(res.data)
      if (!previousGhostHealths.length) {
        setPreviousGhostHealths(res.data.ghostInfos.map((ghostInfo: any) => ghostInfo.ghostHealth))
      }
      setGhostHealths(res.data.ghostInfos.map((ghostInfo: any) => ghostInfo.ghostHealth));
      setGhostInfo(res.data.ghostInfos)
      if (res.data.gameRound) {
        setGameRound(res.data.gameRound)
      }
    }
  }
  const connectWss = () => {
    if (address) {
      // 创建 WebSocket 连接
      const socket = new WebSocket(`wss://gameapi.cellula.fun/ws/${address ? address.toLowerCase() : ''}`);

      // 连接打开时触发
      socket.onopen = () => {
        console.log('WebSocket connected');
      };

      // 接收到消息时触发
      socket.onmessage = (event) => {
        if (event.data !== 'Game over') {
          const res = JSON.parse(event.data)
          if (res) {
            if (res.code === 200) {
              if (firstDataRef.current) {
                continueExecution(res)
              } else {
                updateGameInfo(res)
              }
            } else if (res.code === 326 || res.code === 308) {
              if (firstDataRef.current) {
                continueExecution(res)
              } else {
                updateGameInfo(res)
              }
              setSubmitVisible(true)
              if (res.data.pvpGameInfo.winnerTokenId === tokenId) {
                setEndStatus(1)
              }
            }
          }
        }
      };

      // 连接关闭时触发
      socket.onclose = () => {
        reloadComponent()
        // if (!nextStep.gameRound) {
        //   connectWss()
        // } else {
        //   setTimeout(() => {
        //     if (!submitVisible) {
        //       connectWss()
        //     }
        //   }, 1000)
        // }
        console.log('WebSocket disconnected');
      };

      // 发生错误时触发
      socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      // 设置 WebSocket 连接到状态，以便其他地方使用
      setWs(socket);

      // 组件卸载时关闭 WebSocket 连接
      return () => {
        socket.close();
      };
    }
  }
  useEffect(() => {
    return () => {
      ws?.close();
    };
  }, []);
  const reConnect = () => {
    ws?.close()
    setTimeout(() => {
      firstDataRef.current = null
      setNextStep({})
      setMineIndex(null)
      setOtherIndex(null)
      setOldGhostInfo([])
      setGameInfo(null)
      setGameMap([])
      setGhostInfo([])
      setGhostHealths([])
      setPreviousGhostHealths([])
      connectWss()
    }, 200)
  }
  // edit
  const [actions, setActions] = useState([])
  const [attack, setAttack] = useState(0)
  const [viewRange, setViewRange] = useState(0)
  const [coinPower, setCoinPower] = useState(0)
  const [editStatus, setEditStatus] = useState(false)
  const [options, setOptions] = useState([] as any)

  const handleOnDragEnd = ((result: any) => {
    if (!result.destination) return;

    const itemsCopy = Array.from(options);
    const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, reorderedItem);
    setOptions(itemsCopy);
  })
  const optionChange= (type: string, index: number, value: string) => {
    if (type === 'condition') {
      const newOptions = [...options]
      if (value === 'G') {
        newOptions[index] = value+'&'+ '4'
      } else {
        newOptions[index] = value+'&'+ '1'
      }
      // if (newOptions[index]) {
      //     newOptions[index] = value+'&'+newOptions[index][2]
      // } else {
      //     if (value === 'G') {
      //         console.log(value);
      //         newOptions[index] = value+'&'+ '4'
      //     } else {
      //         newOptions[index] = value+'&'+ '1'
      //     }
      // }
      setOptions(newOptions)
    } else {
      const newOptions = [...options]
      if (newOptions[index]) {
        newOptions[index] = newOptions[index][0]+'&'+value
      } else {
        newOptions[index] = ' '+'&'+value
      }
      setOptions(newOptions)
    }
  }

  const resetPoint = () => {
    clickMusic()
    setCoinPower(coinPower+(attack-5)+((viewRange-6)))
    setAttack(5)
    setViewRange(6)
  }
  const addAttribute = (type: string) => {
    clickMusic()
    if (type === 'attack' && coinPower > 0) {
      setCoinPower(coinPower - 1)
      setAttack(attack + 1)
    } else if (type === 'viewRange' && coinPower > 1) {
      setCoinPower(coinPower - 2)
      setViewRange(viewRange + 2)
    }
  }
  const subtractAttribute = (type: string) => {
    clickMusic()
    if (type === 'attack' && attack > 5) {
      setCoinPower(coinPower + 1)
      setAttack(attack - 1)
    } else if (type === 'viewRange' && viewRange > 6) {
      setCoinPower(coinPower + 2)
      setViewRange(viewRange - 2)
    }
  }
  const saveAttributePolicy = () => {
    clickMusic()
    const formData = {
      ethAddress: address,
      attack: attack,
      viewRange: viewRange,
      tokenId: tokenId,
      moveRule: options.join(','),
      type: 1,
      gameType: 'pvp'
    }
    User.updateAttributePolicy(formData).then((res: any) => {
      if (res.code === 200) {
        setActions(options)
        setEditStatus(false)
      }
    })
  }
  const option1 = [
    {value: 'A', label: 'My Health ≥ 50% & Encounter enemy'},
    {value: 'B', label: 'My Health < 50% & Encounter enemy'},
    {value: 'C', label: 'Score Gap > 20 & Encounter enemy'},
    {value: 'D', label: 'Score Gap < 20 & Encounter enemy'},
    {value: 'G', label: 'Discover prop'},
  ]
  const option2 = [
    {value: '1', label: 'Runaway'},
    {value: '2', label: 'Move towards coins'},
    {value: '3', label: 'Attack'},
    {value: '4', label: 'Move towards prop'},
    {value: '5', label: 'Reverse movement'},
  ]
  function getLabel1ByValue(value: string) {
    const item = option1.find(option => option.value === value);
    return item ? item.label : undefined;
  }
  function getLabel2ByValue(value: string) {
    const item = option2.find(option => option.value === value);
    return item ? item.label : undefined;
  }
  const [endVisible, setEndVisible] = useState(false)
  const enhance = () => {
    clickMusic()
    navigate('/edit')
  }
  const goHome = () => {
    navigate('/home')
  }
  const [isGuide, setIsGuide] = useState(false)
  const [guideStep, setGuideStep] = useState(1)
  useEffect(() => {
    setIsMask(isGuide)
  }, [isGuide]);
  useEffect(() => {
    const guide = localStorage.getItem('pvpGuide')
    const guideStep = localStorage.getItem('pvpStep')
    if (guide && guideStep === '4') {
      setIsGuide(true)
      localStorage.removeItem('pvpStep')
      localStorage.removeItem('pvpGuide')
    }
  }, []);
  return (
    <div className={"game-pvp-container"}>
      <Helmet><title>Multi Player</title></Helmet>
      <div className={"game-pvp-box"}>
        {
          isGuide && guideStep === 1 ? <div className={"text-guide"}>
                <div className={"text-container"}>
                  <Typed
                      strings={[
                        'Game Rounds.'
                      ]}
                      typeSpeed={1}      // 打字速度
                      backSpeed={50}      // 回退速度
                  />
                  <div onClick={() => setGuideStep(2)} className={"button-text-primary"}>
                    <div className={"box"}>
                      OK
                    </div>
                  </div>
                </div>
              </div>
              : ''
        }
        {
          isGuide && guideStep === 2 ? <div className={"text-guide text-guide2"}>
                <div className={"text-container"}>
                  <Typed
                      strings={[
                        'Information about both friendly and enemy "Life" is displayed. Hover over to see more details.'
                      ]}
                      typeSpeed={1}      // 打字速度
                      backSpeed={50}      // 回退速度
                  />
                  <div onClick={() => setGuideStep(3)} className={"button-text-primary"}>
                    <div className={"box"}>
                      OK
                    </div>
                  </div>
                </div>
              </div>
              : ''
        }
        {
          isGuide && guideStep === 3 ? <div className={"text-guide text-guide3"}>
                <div className={"text-container"}>
                  <Typed
                      strings={[
                        '“Ghosts” . Hover over to see more details..'
                      ]}
                      typeSpeed={1}      // 打字速度
                      backSpeed={50}      // 回退速度
                  />
                  <div onClick={() => setGuideStep(4)} className={"button-text-primary"}>
                    <div className={"box"}>
                      OK
                    </div>
                  </div>
                </div>
              </div>
              : ''
        }
        {
          isGuide && guideStep === 4 ? <div className={"text-guide text-guide4"}>
                <div className={"text-container"}>
                  <Typed
                      strings={[
                        'Click "Change Strategy" to modify the action strategy.'
                      ]}
                      typeSpeed={1}      // 打字速度
                      backSpeed={50}      // 回退速度
                  />
                  <div onClick={() => setGuideStep(5)} className={"button-text-primary"}>
                    <div className={"box"}>
                      OK
                    </div>
                  </div>
                </div>
              </div>
              : ''
        }
        {
          isGuide && guideStep === 5 ? <div className={"text-guide text-guide5"}>
                <div className={"text-container"}>
                  <Typed
                      strings={[
                        'Item Details and Duration.'
                      ]}
                      typeSpeed={1}      // 打字速度
                      backSpeed={50}      // 回退速度
                  />
                  <div onClick={() => setIsGuide(false)} className={"button-text-primary"}>
                    <div className={"box"}>
                      OK
                    </div>
                  </div>
                </div>
              </div>
              : ''
        }
        <div className={"pvp-header priority"}>
          <div onClick={backPage} className={isGuide ? 'arrow-icon guide-mask' : 'arrow-icon'}></div>
          <div className={"pvp-header-box"}>
            <div className={isGuide && guideStep !== 2 ? 'user-box guide-mask' : 'user-box'}>
                <div className={"user-detail"}>
                  <div className={"avatar-cover"}>
                    <img src={getSrc('game/other-avatar.png')} alt="" />
                  </div>
                  {
                    otherTotalHealth ? <svg className="progress-ring" width="118" height="118" viewBox="0 0 118 118">
                      <circle strokeDasharray={360} strokeDashoffset={360-(360*(otherHealth/otherTotalHealth))} className="progress-ring__circle" stroke="#FF1C1C" strokeWidth="10" fill="transparent" r="57.3" cx="59" cy="59"/>
                    </svg> : ''
                  }
                </div>
                <div className={"detail-data"}>
                  <div className={"label"}>Score</div>
                  <div className={"value"}>{otherInfo?.nowCoins}</div>
                </div>
              </div>
            <div className={isGuide && guideStep !== 1 ? 'timer-container guide-mask' : 'timer-container'}>
                <div className={"timer-item"}>
                  {round[0]}
                </div>
                <div className={"timer-item"}>
                  {round[1]}
                </div>
                <div className={"timer-item"}>
                  {round[2]}
                </div>
              </div>
            <div className={isGuide && guideStep !== 2 ? 'user-box mine-box guide-mask' : 'user-box mine-box'}>
                <div className={"user-detail"}>
                  <div className={"avatar-cover"}>
                    <img src={getSrc('game/user-avatar.png')} alt="" />
                  </div>
                  {
                    mineTotalHealth ? <svg className="progress-ring" width="118" height="118" viewBox="0 0 118 118">
                      <circle strokeDasharray={360} strokeDashoffset={360-(360*(mineHealth/mineTotalHealth))} className="progress-ring__circle" stroke="#06FFDB" strokeWidth="10" fill="transparent" r="57.3" cx="59" cy="59"/>
                    </svg> : ''
                  }
                </div>
                <div className={"detail-data"}>
                  <div className={"label"}>Score</div>
                  <div className={"value"}>{mineInfo?.nowCoins}</div>
                </div>
              </div>
            </div>
        </div>
        <div className={isGuide && guideStep >= 3 ? 'pvp-main priority' : 'pvp-main'}>
          <div className={isGuide && guideStep !== 3 ? 'ghostInfo guide-mask' : 'ghostInfo'}>
            {ghostInfo.length ? ghostInfo.map((item: any, index:number) => (
                <div key={index} className={`ghost-item ghost-item${index+1}`}>
                  <div className={"avatar-cover"}>
                    <img src={getSrc(`pvp/ghost-${index+1}.png`)} alt="" />
                  </div>
                  <svg className="progress-ring" width="118" height="118" viewBox="0 0 118 118">
                    <circle strokeDasharray={360} strokeDashoffset={360-(360*(item.ghostHealth/item.ghostTotalHealth))} className="progress-ring__circle" stroke="#FF1C1C" strokeWidth="10" fill="transparent" r="57.3" cx="59" cy="59"/>
                  </svg>
                  <div className={"attribute"}>
                    <div className={"view"}>{item.ghostViewRange}</div>
                    <div className={"attack"}>{item.ghostAttack}</div>
                  </div>
                </div>
            )) : ''}
          </div>
          <div className={isGuide && guideStep !== 5 ? 'rewards-container guide-mask' : 'rewards-container priority'}>
            <div className={"rewards-item"}>
              <div className={"reward-icon"}>
                <img src={getSrc('game/apple.png')} alt=""/>
              </div>
              <div style={{width: `${(boosterPill/15)*100}%`}} className={"rewards-rate"}></div>
            </div>
            <div className={"rewards-item"}>
              <div className={"reward-icon"}>
                <img src={getSrc('game/stun.png')} alt=""/>
              </div>
              <div style={{width: `${(vertigoPill/15)*100}%`}} className={"rewards-rate"}></div>
            </div>
          </div>
          <div className={isGuide ? 'map-container guide-mask' : 'map-container'}>
            { gameMap.map((item: any, index: number) => (
                <div key={index} className="map-block">
                  {
                    item.map((items: any, indexs: number) => (
                        <div key={indexs} className={`block block-${['0', '1', '2', '9', '9A', 'd', 'v'].includes(items) ? items : '0'}`}></div>                                                    ))
                  }
                </div>
            ))}
          </div>
          <div className={isGuide && guideStep !== 4 ? 'log-container guide-mask' : 'log-container'}>
            <div className={"log-box"}>
              {
                rulesLog.map((item: any, index:number) => (
                    <div key={index} className={"log-item"}>
                      <div className={"cover"}>
                        <img src={getSrc(`pvp/strategy-${item.ruleName[2]}.png`)} alt=""/>
                      </div>
                      <div className={"right-container"}>
                        <div className={"title"}>{getLabel2ByValue(item.ruleName[2])}</div>
                        <div className={"description"}>{getLabel1ByValue(item.ruleName[0])}</div>
                      </div>
                    </div>
                ))
              }
            </div>
          </div>
          <div className={isGuide && guideStep !== 4 ? 'change-button guide-mask' : 'change-button'}>
            <Button>
              <div onClick={() => setEditStatus(true)}>
                Change Strategy
              </div>
            </Button>
          </div>
          {
            editStatus && attack ? <div className="edit-container">
              <div className={"edit-box"}>
                <img onClick={() => setEditStatus(false)} className={"close-img"} src={getSrc('pvp/close.png')} alt=""/>
                <div className="right-header">
                  <div className="life-detail">
                    <div className="cover-left">
                      <div className="tokenId">
                        TokenId: <span>{tokenId}</span>
                      </div>
                      <div className="cover-box">
                        <img src={getSrc('game/user-avatar.png')} alt=""/>
                        <div className={"light-block block-detail"}>{coinPower}</div>
                        {coinPower >= coinPower+(attack-5)+((viewRange-6)) ? '' : <div onClick={resetPoint} className={"refresh"}></div>}
                      </div>
                      <div className="boold-container">
                        <div className="boold-data-container">
                          <div className="data">{mineHealth}/{mineTotalHealth}</div>
                          <div className="progress-box">
                            <div className="progress-rate" style={{width: ((mineHealth / mineTotalHealth) * 100) + '%'}}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cover-right">
                      <div className="block-back">
                        <div className="block-header">
                          2 <img src={getSrc('setout/smail-light.png')} alt=""/>
                          / + 1</div>
                        <div className={"view-block block-detail block-function"}>
                          <span>{viewRange/2}</span>
                          <div className={"add-block"} onClick={() => addAttribute('viewRange')}></div>
                          <div className={"add-block subtract-block"} onClick={() => subtractAttribute('viewRange')}></div>
                        </div>
                      </div>
                      <div className="block-back">
                        <div className="block-header">
                          1 <img src={getSrc('setout/smail-light.png')} alt=""/>
                          / + 1</div>
                        <div className={"attack-block block-detail block-function"}>
                          <span>{attack}</span>
                          <div className={"add-block"} onClick={() => addAttribute('attack')}></div>
                          <div className={"add-block subtract-block"} onClick={() => subtractAttribute('attack')}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="edit-strategy-box">
                  <div className={"title-edit"}>
                    Action Strategy
                  </div>
                  <div className={"table-container"}>
                    <div className="label">Order</div>
                    <div className="label">Condition</div>
                    <div className="label">Action</div>
                  </div>
                  <div className={"option-container"}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="list">
                        {(provided: any) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {options.map((item: any, index: number) => (
                                  <Draggable key={index} draggableId={item+index} index={index}>
                                    {(provided: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={index}
                                            className="option-item"
                                        >
                                          <div className="label-index">{index + 1}</div>
                                          <div className={"select-container"}>
                                            <div className="select-item">
                                              <Select
                                                  value={item[0]}
                                                  onChange={(value) => optionChange('condition', index, value)}
                                                  className={item[0] ? 'value-item' : 'value-item no-value'}
                                                  placeholder={"Make your selection"}
                                                  onFocus={clickMusic}
                                                  options={condition.map((item: any) => ({ value: item.value, label: item.label }))}
                                              />
                                            </div>
                                            <div className="select-item">
                                              <Select
                                                  value={options[index].length === 3 && options[index][0] !== 'G' ? action[options[index][0]][parseInt(options[index][2])-1].label : options[index].length === 3 ? action[options[index][0]][parseInt(options[index][2]) - 4].label : null}
                                                  className={item[0] ? 'value-item' : 'value-item no-value'}
                                                  onFocus={clickMusic}
                                                  onChange={(value) => optionChange('action', index, value)}
                                                  options={action[options[index][0]]}
                                                  placeholder={"Make your selection"}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                    )}
                                  </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <div className="edit-button-container">
                      <Button small={true}>
                        <div onClick={() => setEditStatus(false)}>
                          Back
                        </div>
                      </Button>
                      <Button small={true}>
                        <div onClick={saveAttributePolicy}>
                          Confirm
                          <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                : ''
          }
        </div>
      </div>
      <Modal
          open={endVisible}
          className={"end-modal"}
          width={740}
          getContainer={false}
          footer={[
            <Button small={true} key="back">
              <div className={"back"} onClick={goHome}>
                Home
              </div>
            </Button>
          ]}
      >
        <div className="end-modal">
          {
            endStatus ? <div className={"end-top"}>
                  <div className={"logo-end"}></div>
                  <div className={"detail-data"}>
                    <div className="detail-tiem">
                      <img src={getSrc('game/coin.png')} alt=""/>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M20.999 6.99805L6.99902 20.998" stroke="#FFE500" strokeWidth="4.48" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21.001 20.998L7.00098 6.99805" stroke="#FFE500" strokeWidth="4.48" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      240
                    </div>
                  </div>
                </div>
                : <div className={"end-top end-failed"}>
                  <div className={"logo-failed"}></div>
                  <div className={"failed-description"}>
                    Your life with Token ID: <span>{tokenId}</span> Failure, the game is over!
                  </div>
                </div>
          }
        </div>
      </Modal>
      <Modal
          open={submitVisible}
          className={"end-modal"}
          width={1000}
          getContainer={false}
          footer={null}
      >
        <div className="submit-modal">
          The battle has ended. Please submit the game results to proceed with the settlement.
          <Button isLoading={count !== 0} key="Confirm">
            <div onClick={submitContract}>
              Submit
            </div>
          </Button>
        </div>
      </Modal>
    </div>
)
}

export function GamePVP() {
  const [key, setKey] = useState(0);

  function reloadComponent() {
    // 更新 key 的状态会导致 MyComponent 卸载并重新挂载
    setKey(prevKey => prevKey + 1);
  }

  return (
      <div>
        <Game key={key} reloadComponent={reloadComponent}></Game>
      </div>
  )
}