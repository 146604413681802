import "./index.scss"
import {clickMusic, getSrc, useCountdownSeconds} from "../../utils/utils";
import Button from "../../components/Button"
import { PVPNftItem } from "./nftItem";
import { useNavigate, useParams } from "react-router-dom";
import {Pvp, User} from "../../api"
import { useEffect, useState } from "react";
import {useAccount, useContractWrite} from "wagmi";
import {wagmigotchiContract} from "../../utils/contract";
import {useGlobalState} from "../../hook/useGlobalState";
import {Helmet} from "react-helmet";
import Typed from "react-typed";

export function GamesLobby() {
  const navigate = useNavigate()
  const { tokenId } = useParams()
  const { address } = useAccount()
  const [rooms, setRooms] = useState([])
  const {userLifeList, isNetwork, setRoomId, setIsMask, roomId, setUserLifeList} = useGlobalState()
    const [nftDetail, setNftDetail] = useState({})

  const backPage = () => {
    navigate(-1)
  }
  const { isSuccess, write, isLoading } = useContractWrite({
    ...wagmigotchiContract,
    functionName: 'saveLifeInfo'
  })
  function checkArray(strings: any) {
    return strings.some((str: any) => str.length === 3 && !str.includes(' '));
  }
  const createRoom = () => {
    const nftItem = userLifeList.filter((item: any) => { return item.tokenId === tokenId })
    if (nftItem.length && !roomId) {
      const options = nftItem[0].gameLifeEntityInfo.pvpMoveRule.split(',')
      if (checkArray(options) && isNetwork) {
        clickMusic()
        write({args: [parseInt(nftItem[0].gameLifeEntityInfo.tokenId),nftItem[0].gameLifeEntityInfo.viewRange,nftItem[0].gameLifeEntityInfo.attack,options.join(',')]})
      }
    }
  }
  const [total, setTotal] = useState(0)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(6)

  function calculateTotalPages(total: number) {
    return Math.ceil(total / 6) === 0 ? 1 : Math.ceil(total / 6);
  }

  const getRooms = (pageNumValue?: any) => {
    User.getMyLifes(address).then((res: any) => {
      setUserLifeList(res.data)
    })
      Pvp.getGame(address).then((res: any) => {
          if (res.code === 200) {
              setNftDetail(res.data.roomInfo)
              if (res.data.roomId) {
                  setRoomId(res.data.roomId)
              }
          }
      })
    Pvp.getRooms(address, pageSize, pageNumValue).then((res: any) => {
      if (res.data.list) {
        setRooms(res.data.list)
        setTotal(res.data.total)
      }
    })
  }
  useEffect(() => {
  }, []);
  useEffect(() => {
    getRooms()
    const intervalId = setInterval(() => {
      getRooms(pageNum);
    }, 2000);

    // 返回的函数将在组件卸载时调用，清除定时器
    return () => clearInterval(intervalId);
  }, [pageNum]); // 现在 useEffect 依赖于 pageNum，pageNum 改变时会重新运行
  const setPageNumFun = (pageNumStatus: number) => {
    if (pageNumStatus) {
      if (pageNum !== calculateTotalPages(total)) {
        const num = pageNum + 1
        setPageNum(num)
        getRooms(num)
      }
    } else {
      if (pageNum !== 1) {
        const num = pageNum - 1
        setPageNum(num)
        getRooms(num)
      }
    }
  }
  const { count, countdown } = useCountdownSeconds()

  useEffect(() => {
    if (isLoading) {
      countdown(60)
    } else if (!isSuccess) {
      countdown(0)
    }
  }, [isLoading]);
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        Pvp.createRoom({ethAddress: address, tokenId: tokenId}).then((res: any) => {
          if (res.code === 200) {
            navigate(`/create-lobby/${tokenId}`)
          }
        })
      }, 2000)
    }
  }, [isSuccess]);
  const [isGuide, setIsGuide] = useState(false)
  const [guideStep, setGuideStep] = useState(1)
  useEffect(() => {
    setIsMask(isGuide)
  }, [isGuide]);
  useEffect(() => {
    const guide = localStorage.getItem('pvpGuide')
    const guideStep = localStorage.getItem('pvpStep')
    if (guide && guideStep === '3') {
      setIsGuide(true)
      localStorage.setItem('pvpStep', '4')
    }
  }, []);
  return (
    <div className={"games-lobby"}>
      <Helmet><title>Room Lobby</title></Helmet>
      <div className={isGuide ? 'lobby-main priority' : 'lobby-main'}>
        {
          isGuide && guideStep === 1 ? <div className={"text-guide"}>
                <div className={"text-container"}>
                  <Typed
                      strings={[
                        'Here, you can select the opponent you want to battle against. Click "Join the Game" to join.'
                      ]}
                      typeSpeed={1}      // 打字速度
                      backSpeed={50}      // 回退速度
                  />
                  <div onClick={() => setGuideStep(2)} className={"button-text-primary"}>
                    <div className={"box"}>
                      OK
                    </div>
                  </div>
                </div>
              </div>
              : ''
        }
        {
          isGuide && guideStep === 2 ? <div className={"text-guide text-guide2"}>
                <div className={"text-container"}>
                  <Typed
                      strings={[
                        'Click here to create a new room and play with your friends.'
                      ]}
                      typeSpeed={1}      // 打字速度
                      backSpeed={50}      // 回退速度
                  />
                  <div onClick={() => setIsGuide(false)} className={"button-text-primary"}>
                    <div className={"box"}>
                      OK
                    </div>
                  </div>
                </div>
              </div>
              : ''
        }
        <div className={isGuide ? 'lobby-header guide-mask' : 'lobby-header'}>
          <img
            onClick={backPage}
            className={"arrow-icon"}
            src={getSrc("setout/arrow-left.png")}
            alt=""
          />
        </div>
        <div className={"lobby-box"}>
          <div className={isGuide && guideStep !== 2 ? 'header-button guide-mask' : 'header-button'}>
            <Button isLoading={count !== 0}>
              <div onClick={() => createRoom()}>
                Create a match
              </div>
            </Button>
          </div>
          <div className={isGuide && guideStep !== 1 ? 'nft-container guide-mask' : 'nft-container'}>
            { rooms.map((item, index) => (
              <PVPNftItem key={index} nftDetail={item}></PVPNftItem>
            )) }
          </div>
          <div className={isGuide ? 'page-container guide-mask' : 'page-container'}>
            <div className={"page-box"}>
              <div onClick={() => setPageNumFun(0)} className={"left-img"}></div>
              <span className={"page-text"}>{pageNum}/{calculateTotalPages(total)}</span>
              <div onClick={() => setPageNumFun(1)} className={"right-img"}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}