import { useState, useEffect, useRef, useCallback } from 'react';
import Click from "../assets/musics/click.wav"
export function useCountdownSeconds() {
    const [count, setCount] = useState<number>(0);
    const intervalId = useRef<NodeJS.Timeout | null>(null);

    const countdown = useCallback(
        (initCount: number, callback?: any, timeInterval = 1000) => {
            setCount(initCount);
            intervalId.current = setInterval(() => {
                setCount(prevCount => {
                    if (prevCount <= 1) {
                        clearInterval(intervalId.current as NodeJS.Timeout);
                        callback?.();
                        return 0;
                    } else {
                        return prevCount - 1;
                    }
                });
            }, timeInterval);
        },
        []
    );

    useEffect(() => {
        return () => {
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
        };
    }, []);

    return {
        count,
        countdown,
        setCount
    };
}
export const getSrc = (name: string) => {
    const path = `/src/assets/images/${name}`;
    const modules = import.meta.globEager('/src/assets/images/**/*') as Record<string, { default: string }>;

    if (modules[path]) {
        return modules[path].default;
    } else {
        console.warn(`Image not found: ${name}`);
        return '';
    }
};

export function debounce<T extends (...args: any[]) => any>(func: T, wait: number, context: any): T {
    let timeout: NodeJS.Timeout | null = null;

    return ((...args: Parameters<T>): ReturnType<T> => {
        let result: any;

        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }

        timeout = setTimeout(() => {
            result = func.apply(context, args);
        }, wait);

        return result;
    }) as T;
}

export function getStr(string: any, forward: number, later: number) {
    const strLength = string?.length
    return string ? string.substring(0, forward) + '****' + string.substring(strLength - later, strLength) : ''
}
export function clickMusic() {
    const audioClick = new Audio(Click);
    audioClick.play()
}
type Matrix = string[][];
type Position = { i: number, j: number, name: string };

export function findCharacters(matrix: Matrix, characters: string[]): Position[] {
    const foundPositions: Position[] = [];

    matrix.forEach((row, i) => {
        row.forEach((cell, j) => {
            if (characters.includes(cell)) {
                foundPositions.push({ i, j, name: cell });
            }
        });
    });

    return foundPositions;
}
export function extractFirstNumberString(str?: string) {
    // 正则表达式匹配第一个连续数字串
    if (str) {
        const match = str.match(/\d+/);

        // 如果有匹配项，返回它
        return match ? match[0] : null;
    } else {
        return ''
    }
}