import "./NavButton.scss"
import { getSrc } from "../utils/utils"
import React from "react";

type ButtonProps = {
    children: React.ReactNode,
    disabled?: boolean,
    isLoading?: boolean,
    small?: boolean,
    mid?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, disabled = false, isLoading = false, small = false, mid = false }) => {
    let buttonClass = 'button-default';

    if (disabled) {
        buttonClass += ' button-disabled';
    }

    if (small) {
        buttonClass += ' small';
    }
    if (mid) {
        buttonClass += ' middle'
    }
    return <div className={buttonClass}>
        {isLoading ? <div className={"loading-box"}><img className={"load-img"} src={getSrc('layout/loading.png')} alt=""/></div> : children}
    </div>;
};

export default Button;