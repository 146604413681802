import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../hook/useGlobalState';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { isWhiteList } = useGlobalState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isWhiteList) {
      navigate('/welcome');
    }
  }, [isWhiteList, navigate]);

  if (!isWhiteList) {
    return null;
  }

  return <>{children}</>;
};