import React, {useRef, useEffect} from 'react';
import * as THREE from 'three';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

const ThreeModel: React.FC = () => {
    const mountRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // 创建渲染器
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);

        // 添加渲染器的 DOM 元素到 React 组件
        mountRef.current?.appendChild(renderer.domElement);

        // 创建场景
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0x8fbcd4);

        // 添加灯光
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight.position.set(1, 1, 1); // 可以根据需要调整位置
        scene.add(directionalLight);

        // 创建相机
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 5;

        // 创建OrbitControls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = true;

        // 加载OBJ和MTL文件
        const mtlLoader = new MTLLoader();
        mtlLoader.load('/../../obj/ghost.mtl', (materials) => {
            materials.preload();
            const objLoader = new OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.load('/../../obj/ghost.obj', (obj) => {
                scene.add(obj);
                animate();
            });
        });

        // 调整渲染器尺寸和相机的纵横比
        const onWindowResize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', onWindowResize, false);

        // 动画循环渲染
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };

        // 清理资源
        return () => {
            window.removeEventListener('resize', onWindowResize, false);
            mountRef.current?.removeChild(renderer.domElement);
            scene.clear();
            renderer.dispose();
        };
    }, []);

    return <div ref={mountRef}/>;
};

export default ThreeModel;