import "./index.scss"
import {useGlobalState} from "../../hook/useGlobalState";
import Button from "../../components/Button";
import {useNavigate} from "react-router-dom";
import {message, Modal} from "antd";
import {useState} from "react";
import {Helmet} from "react-helmet";
import {cellulaUrl} from "../../utils/contract";
import {getSrc} from "../../utils/utils";
export function HomePage() {
    const {coins, setBugVisible, setClaimVisible, userLifeList} = useGlobalState()
    const [modeStatus, setModeStatus] = useState(0)
    const navigate = useNavigate()
    const goToPage = (path: string) => {
        navigate(path)
    }
    const comingSoon = () => {
        message.info('Coming Soon')
    }
    const guideFun = () => {
        // localStorage.setItem('pvpGuide', '1')
        // localStorage.setItem('pvpStep', '1')
        localStorage.setItem('pveGuide', '1')
        localStorage.setItem('pveStep', '1')
        goToPage('/pve')
    }
    const [noDataVisible, setNoDataVisible] = useState(false)
    const noDataModalCancel = () => {
        setNoDataVisible(false);
    };
    const openUrl = (url: string) => {
        window.open(url)
        noDataModalCancel()
    }
    const goToPvp = () => {
        if (userLifeList.length) {
            goToPage('/pvp-setout')
        } else {
            setNoDataVisible(true)
        }
    }
    const goToActivities = () => {
        navigate('/tournamant')
    }
    return (
        <div className={"home-page"}>
            <Helmet><title>AW PAC-MAN</title></Helmet>
            <div className={"home-main"}>
                <div className={"point-container"}>
                    <div className={"point-box"}>
                        {coins}
                    </div>
                </div>
                <div className={"home-main-box"}>
                    <div className={"left-container"}>
                        <div className={"button-item"}>
                            <Button>
                                <div onClick={() => goToActivities()}>
                                    <div className={"gift-icon"}></div>
                                    <span className={"text"}>Tournamant</span>
                                </div>
                            </Button>
                        </div>
                        <div className={"button-item"}>
                            <Button>
                                <div onClick={() => goToPage('/leaderboard')}>
                                    <div className={"rank-icon"}></div>
                                    Leaderboard
                                </div>
                            </Button>
                        </div>
                        <div className={"button-item"}>
                            <Button>
                                <div onClick={() => setBugVisible(true)}>
                                    <div className={"bug-icon"}></div>
                                    Report bug
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div className={"right-container"}>
                        <div onClick={guideFun} className={"guide-img"}></div>
                        <div className={"person-container"}>
                            <div className={modeStatus ? 'person-1 person-1-hover' : 'person-1'}></div>
                            <div className={!modeStatus || modeStatus ? 'person-2 person-2-hover' : 'person-2'}></div>
                        </div>
                        <div className={!modeStatus ? 'left-cover left-cover-hover' : 'left-cover'}>
                            <div className={!modeStatus ? 'text-box text-box-hover' : 'text-box'}>
                                Single Player
                            </div>
                        </div>
                        <div className={modeStatus ? 'right-cover right-cover-hover' : 'right-cover'}>
                            <div className={modeStatus ? 'text-box text-box-hover' : 'text-box'}>
                                Multi Player
                            </div>
                        </div>
                        <div className="pvp-button">
                            <div onMouseOver={() => setModeStatus(0)} onClick={() => goToPage('/pve')} className="rectangle"></div>
                        </div>
                        <div className="pve-button">
                            <div onMouseOver={() => setModeStatus(1)} onClick={goToPvp} className="rectangle"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={noDataVisible}
                className={"nodata-modal-container"}
                onCancel={noDataModalCancel}
                getContainer={false}
                closeIcon={false}
                maskClosable={false}
                keyboard={false}
                footer={[
                    <Button key="Confirm">
                        <div onClick={() => openUrl(cellulaUrl)}>
                            Craft
                            <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                        </div>
                    </Button>
                ]}
            >
                <div className="nodata-modal">
                    <div className={"title"}>
                        {'Your current account does not have any "life" NFTs in an available state. Please try another account or go to Craft life.'}
                    </div>
                    <img className={"nodata-img"} src={getSrc('pve/nodata.png')} alt=""/>
                </div>
            </Modal>
        </div>
    )
}