import "./index.scss"
import Button from "../../components/Button";
import React, {useEffect, useState} from "react";
import {clickMusic, getSrc, getStr, useCountdownSeconds} from "../../utils/utils";
import { Pvp } from "../../api";
import {useAccount, useContractWrite} from "wagmi";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {wagmigotchiContract} from "../../utils/contract";
import {useGlobalState} from "../../hook/useGlobalState";
import {message} from "antd";

type PVPNftItemProps = {
  nftDetail?: any
  isData?: boolean
}

export const PVPNftItem: React.FC<PVPNftItemProps> = ({ nftDetail, isData = false }) => {
  const location = useLocation();
  const { address } = useAccount()
  const { tokenId } = useParams()
    const {isNetwork, userLifeList, roomId} = useGlobalState()
  const emptyArray = new Array(39).fill(undefined);
  const [bannerCurrent, setBannerCurrent] = useState(1)
  const navigate = useNavigate()
  const { isSuccess, write, isLoading } = useContractWrite({
    ...wagmigotchiContract,
    functionName: 'saveLifeInfo'
  })
  const { count, countdown } = useCountdownSeconds()

  const joinGame = () => {
    if (!location.pathname.includes('/create-lobby')) {
        if (nftDetail?.pvpGameId !== roomId) {
            if (!roomId) {
            const nftItem = userLifeList.filter((item: any) => {
                return item.tokenId === tokenId
            })
            if (nftItem.length) {
                if (isNetwork) {
                    clickMusic()
                    write({args: [parseInt(nftItem[0].gameLifeEntityInfo.tokenId), nftItem[0].gameLifeEntityInfo.viewRange, nftItem[0].gameLifeEntityInfo.attack, nftItem[0].gameLifeEntityInfo.pvpMoveRule]})
                }
            }
            }
        } else {
            navigate(`/create-lobby/${nftDetail?.tokenId}`)
      }
    }
  }
  useEffect(() => {
    if (isLoading) {
      countdown(60)
    } else if (!isSuccess) {
      countdown(0)
    }
  }, [isLoading]);
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        const formData = {
          playerAddress: address,
          roomId: nftDetail?.pvpGameId,
          tokenId: tokenId
        }
        Pvp.joinRoom(formData).then((res: any) => {
          if (res.code === 200) {
            navigate(`/game-pvp/${tokenId}`)
          }
        })
      }, 2000)
    }
  }, [isSuccess]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setBannerCurrent(prev => {
        if (prev >= 39) {
          return 1;
        } else {
          return prev + 1;
        }
      });
    }, 200);

    // 组件卸载时清除定时器
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className={"pvp-nft-container"}>
      {
        isData ? <div className={"pvp-nft-item pvp-nft-item-unknown"}>
          {
            emptyArray.map((item, index) => (
                <img key={index} className={bannerCurrent === index + 1 ? 'cover-img unknown-img active' : 'cover-img unknown-img'} src={getSrc(`life/avatar_${index+1}.png`)} />
            ))
          }
          <div className={"unknown-icon"}>
            <div className={"unknown-item"}></div>
            <div className={"unknown-item"}></div>
            <div className={"unknown-item"}></div>
          </div>
        </div>
          :
          <div>
            <div className={"pvp-nft-item"} onClick={joinGame}>
              <div className={"cover-img"}>
                <img src={nftDetail?.avatarUrl} alt="" />
              </div>
              <div className={"health-block"}>{nftDetail?.health}</div>
              <div className={"attack-block"}>
                {nftDetail?.attack}
              </div>
              <div className={"view-block"}>
                {nftDetail?.viewRange}
              </div>
              <div className={"address-block"}>{getStr(nftDetail?.ethAddress,4,4)}</div>
            </div>
            <div className={"button-container"}>
                <Button small={true} disabled={roomId && roomId !== nftDetail?.pvpGameId ? true : false}
                        isLoading={count !== 0}>
                <div onClick={joinGame}>
                  Join the game
                </div>
              </Button>
            </div>
          </div>
      }
    </div>
  )
}