// GlobalStateContext.tsx
import { createContext } from 'react';

interface GlobalState {
    isWhiteList: boolean;
    setIsWhiteList: (value: boolean) => void;
    coins: number,
    setCoins: (value: number) => void;
    exitGame: boolean;
    setExitGame: (value: boolean) => void;
    userLifeList: any;
    setUserLifeList: (value: any) => void
    isNetwork: boolean
    setIsNetwork: (value: any) => void
    bannerVisible: boolean
    setBannerVisible: (value: any) => void
    claimVisible: boolean
    setClaimVisible: (value: any) => void
    bugVisible: boolean
    setBugVisible: (value: any) => void
    roomId: number
    setRoomId: (id: any) => void
    isMask: boolean
    setIsMask: (value: boolean) => void,
    network: number
    setNetwork: (value: number) => void
}

export const GlobalStateContext = createContext<GlobalState | undefined>(undefined);