import { useEffect, useState } from "react";
import {clickMusic, getSrc} from "../../utils/utils";
import "./index.scss"
import { SelectionLife } from "./selectionLife"
import { SelectionStrategy } from "./selectionStrategy"
import { NewSelectionLife } from "./newSelectionLife"
import { useNavigate, useParams } from 'react-router-dom';
import Button from "../../components/Button";
import { useAccount } from 'wagmi';
import { User } from '../../api';
import {Helmet} from "react-helmet";
import {useGlobalState} from "../../hook/useGlobalState";
export function PvpSetout() {
    const navigate = useNavigate()
    const { address } = useAccount()
    const [userLifeList, setUserLifeList] = useState([] as any)
    const backgroundImages = ['life','strategy']
    const [step, setStep] = useState(1)
    const {coins} = useGlobalState()
    const [activeIndex, setActiveIndex] = useState(0)
    useEffect(() => {
        if (address) {
            User.getMyLifes(address).then((res: any) => {
                setUserLifeList(res.data)
            })
        }
    }, [address]);
    const setActiveIndexFun = (index: number) => {
        setActiveIndex(index)
    }
    const switchStatus = () => {
        if (step === 1) {
            setStep(2)
        } else {
            setStep(1)
        }
    }
    const backFun = () => {
        clickMusic()
        if (step === 1) {
            navigate('/home')
        } else {
            switchStatus()
        }
    }
    const refreshData = (() => {
        User.getMyLifes(address).then((res: any) => {
            setUserLifeList(res.data)
        })
    })
    return (
        <div className={step === 2 ? "setout-container setout-container-2" : "setout-container"}>
            <Helmet><title>Multi Player</title></Helmet>
            <div className={"navigation-container"}>
                <div className={"nav-box"}>
                    <img onClick={backFun} className={"arrow"} src={ getSrc('setout/arrow-left.png') } alt=""/>
                    {
                        step === 1 ? <div className={"nav-item"}>Life List</div> : ''
                    }
                </div>
                <div className={"point-box"}>
                    {coins}
                </div>
            </div>
            {userLifeList.length ?
                <div className={"setout-main"}>
                    {step === 1 ?
                        <NewSelectionLife userLifeList={userLifeList} activeIndex={activeIndex} nextStep={switchStatus}
                                          setActiveIndexFun={setActiveIndexFun} refreshData={refreshData}/> :
                        <SelectionStrategy lifeDetail={userLifeList[activeIndex]} refreshData={refreshData}/>}
                </div>
                :
                ''
            }
        </div>
    )
}