import "./new-index.scss"
import {clickMusic, getSrc, useCountdownSeconds} from "../../utils/utils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useGlobalState} from "../../hook/useGlobalState";
import {User} from "../../api";
import {useAccount, useContractWrite} from "wagmi";
import {Modal} from "antd";
import Button from "../../components/Button";
import {cellulaUrl, wagmigotchiContract} from "../../utils/contract";
import {Helmet} from "react-helmet";
import Typed from 'react-typed';

export function PveMap() {
    const navigate = useNavigate()
    const location = useLocation()
    const { address, isConnected } = useAccount()
    const { levelCurrent} = useParams()
    const levels = new Array(10).fill(null)
    const levels2 = new Array(6).fill(null)
    const [roads, setRoads] = useState(new Array(9).fill(null))
    const {coins, setCoins, userLifeList, isNetwork, bannerVisible, setIsMask} = useGlobalState()
    const [noDataVisible, setNoDataVisible] = useState(false)
    const scrollAmount = 600;
    const scrollDirection = ((direction: string) => {
        return function handleClick () {
            const levelContainer = document.querySelector('.container');
            if (levelContainer) {
                clickMusic()
                if (direction === 'right') {
                    levelContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
                } else {
                    levelContainer.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
                }
            } else {
                console.error('.level-container not found');
            }
        }
    })
    const goToSetOut = (level: number) => {
        clickMusic()
        if (userLifeList.length) {
            if (gameType) {
                console.log(level);
                if (level === 1) {
                    navigate(`/pve-setout/${level}`)
                } else {
                    if (mapList[level-2].passed) {
                        navigate(`/pve-setout/${level}`)
                    }
                }
            } else {
                navigate(`/pve-setout/${level+10}`)
            }
        } else {
            setNoDataVisible(true)
        }
    }

    const goToPage = (url: string) => {
        clickMusic()
        if (url === '/edit' && !userLifeList.length) {
            setNoDataVisible(true)
            return
        }
        navigate(url)
    }
    const [mapList, setMapList] = useState([] as any)
    const [activeLevel, setActiveLevel] = useState(parseInt(levelCurrent ? levelCurrent : '1') as any)
    const [lifeDetail, setLifeDetail] = useState(null as any)
    const [modalVisible, setModalVisible] = useState(false)
    const { isSuccess, write, isLoading } = useContractWrite({
        ...wagmigotchiContract,
        functionName: 'saveLifeInfo'
    })
    useEffect(() => {
        if (isLoading) {
            countdown(60)
        } else {
            countdown(0)
        }
    }, [isLoading]);
    const { count, countdown } = useCountdownSeconds()
    const submitContract = () => {
        if (lifeDetail && isNetwork) {
            write({args: [parseInt(lifeDetail.tokenId),lifeDetail.viewRange,lifeDetail.attack,lifeDetail.moveRule]})
        }
    }
    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                // submit bill
                User.payBill(address).then((res: any) => {
                    if (res.code === 200) {
                        setModalVisible(false)
                    }
                })
            }, 5000)
        }
    }, [isSuccess]);
    useEffect(() => {
        if (address) {
            User.syncUser(address).then()
            User.checkNotPayBill(address).then((res: any) => {
                if (res.data) {
                    setLifeDetail(res.data)
                    setModalVisible(true)
                }
            })
            User.getUserCoins(address).then((res: any) => {
                setCoins(res.data)
            })
            User.getPassLevels(address).then((res: any) => {
                setMapList(res.data)
            })
        }
    }, [address])
    useEffect(() => {
        if (!gameType) {
            const name = names[activeLevel-1]
            if (name) {
                navigate(`/pve?name=${name}`)
            }
            setTranslateX((activeLevel-1)*fixedRemDistance)
        } else {
            if (isFirstRender3.current) {
                isFirstRender3.current = false
            } else {
                sessionStorage.setItem('level', activeLevel)
            }
            setTranslateX((activeLevel-1)*fixedRemDistance)
        }
    }, [activeLevel]);
    const noDataModalCancel = () => {
        setNoDataVisible(false);
    };
    const openUrl = (url: string) => {
        window.open(url)
        noDataModalCancel()
    }
    const backFun = () => {
        navigate('/home')
    }
    const [translateX, setTranslateX] = useState(0);
    const fixedRemDistance = 3.45833;
    const containerRef = useRef(null as any);

    // 确保你的事件处理器使用函数式更新获取最新的状态
    const handleWheel = (event: any) => {
        event.preventDefault();
        const mapItemsContainer = containerRef.current.querySelector('.map-items-container');
        const elementCount = mapItemsContainer.children.length; // 使用.children而不是.childNodes忽略非元素节点
        setTranslateX((prevTranslateX) => {
            const maxTranslateX = fixedRemDistance * (elementCount-1);
            let newTranslateX = prevTranslateX + (event.deltaY > 0 ? fixedRemDistance : -fixedRemDistance);
            newTranslateX = Math.max(0, Math.min(newTranslateX, maxTranslateX));
            return newTranslateX;
        });
    };
    const currentImageIndex = Math.round(translateX / fixedRemDistance);

    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const name = queryString.get('name');
        if (name) {
            setActiveLevel(names.indexOf(name) + 1)
            setTranslateX(names.indexOf(name) * fixedRemDistance)
            setGameType(false)
        } else {
            const level = sessionStorage.getItem('level')
            if (level) {
                setActiveLevel(parseInt(level))
            }
        }
        const container: any = containerRef.current;
        container.addEventListener('wheel', handleWheel, { passive: false });
        return () => {
            container.removeEventListener('wheel', handleWheel);
        };
    }, []); // 这里不需要添加依赖，因为我们使用了函数式更新
    const isFirstRender = useRef(true);
    const isFirstRender2 = useRef(true);
    const isFirstRender3 = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
        } else {
            setActiveLevel(currentImageIndex+1)
        }
    }, [currentImageIndex]);
    const [specialVisible, setSpecialVisible] = useState(false)
    const [gameType, setGameType] = useState(true)
    const names = ['cellula', 'bnbchain', 'dmail', 'ultiverse', 'metamerge', 'zypher']
    useEffect(() => {
        if (!gameType) {
            if (activeLevel > 6) {
                setActiveLevel(1)
                setTranslateX(0)
                navigate('/pve?name=cellula')
            } else {
                navigate(`/pve?name=${names[activeLevel-1]}`)
            }
        } else {
            if (isFirstRender2.current) {
                isFirstRender2.current = false
            } else {
                sessionStorage.setItem('level', activeLevel)
                navigate(`/pve`)
            }
        }
    }, [gameType]);
    const [isGuide, setIsGuide] = useState(false)
    useEffect(() => {
        setIsMask(isGuide)
    }, [isGuide]);
    useEffect(() => {
        const guide = localStorage.getItem('pveGuide')
        const guideStep = localStorage.getItem('pveStep')
        if (guide && guideStep === '1') {
            setIsGuide(true)
            localStorage.setItem('pveStep', '2')
        }
    }, []);
    return (
        <div className={'pve-container'}>
            <Helmet><title>Single Player</title></Helmet>
            {
                isGuide ? <div className={"text-guide"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    'You can switch maps by scrolling the mouse wheel or simply click on the map you want to explore.'
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setIsGuide(false)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <div className={"back-container"}>
                <img onClick={backFun} className={"arrow"} src={ getSrc('setout/arrow-left.png') } alt=""/>
            </div>
            <div className="person-container"></div>
            {
                gameType ? <div className="map-cover">
                    <div className={"map-box"} ref={containerRef}>
                        <div
                            className={"map-items-container"}
                            style={{transform: `translate3d(-${translateX}rem, 0, 0)`}}
                        >
                            {
                                levels.map((item: any, index) => (
                                    <div key={index} className={activeLevel !== 1 ? (!mapList[activeLevel-2]?.passed ? "map-item lock-map" : 'map-item') : 'map-item'}>
                                        <img src={getSrc(`pve/map${activeLevel}.png`)} alt=""/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                    : <div className="map-cover">
                        <div className={"map-box"} ref={containerRef}>
                            <div
                                className={"map-items-container"}
                                style={{transform: `translate3d(-${translateX}rem, 0, 0)`}}
                            >
                                {
                                    levels2.map((item: any, index) => (
                                        <div key={index} className={'map-item'}>
                                            <img src={getSrc(`map-img/activity-map-icon-${activeLevel}.png`)} alt=""/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className="button" onClick={() => goToSetOut(activeLevel)}>
            </div>
            <div className={isGuide ? 'level-container priority' : 'level-container'}>
                <div onClick={() => setGameType(!gameType)} className={"level-item level-item-active-special"}>
                    <div className={!gameType ? 'level-text level-text-active' : 'level-text'}>
                        <img className={"gift-img"} src={getSrc(`pve/${!gameType ? 'gift-active' : 'gift'}.png`)} alt=""/>
                    </div>
                </div>
                {
                    gameType ? levels.map((item, index) => (
                        <div onClick={() => setActiveLevel(index+1)} key={index} className={activeLevel === index+1 ? 'level-item level-item-active' : 'level-item'}>
                            <div className="level-text">
                                {index+1}
                            </div>
                        </div>
                    ))
                        :
                        levels2.map((item, index) => (
                            <div onClick={() => setActiveLevel(index+1)} key={index} className={activeLevel === index+1 ? 'level-item level-item-active' : 'level-item'}>
                                <div className={mapList[index+10]?.passed ? 'level-text img-box level-tick' : "level-text img-box" } >
                                    <img className={mapList[index+10]?.passed ? 'active-img' : ''} src={getSrc(`map-img/icon-${index + 1}.png`)} alt=""/>
                                </div>
                            </div>
                        ))
                }
            </div>
            <Modal
                open={modalVisible}
                className={"end-modal"}
                width={1000}
                getContainer={false}
                footer={null}
                maskClosable={false}
                keyboard={false}
            >
                <div className="submit-modal">
                    Please submit the results of your previous match. Once submitted, you may continue playing.
                    <Button isLoading={count !== 0} key="Confirm">
                        <div onClick={submitContract}>
                            Submit
                        </div>
                    </Button>
                </div>
            </Modal>
            <Modal
                open={noDataVisible}
                className={"nodata-modal-container"}
                onCancel={noDataModalCancel}
                getContainer={false}
                closeIcon={false}
                maskClosable={false}
                keyboard={false}
                footer={[
                    <Button key="Confirm">
                        <div onClick={() => openUrl(cellulaUrl)}>
                            Craft
                            <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                        </div>
                    </Button>
                ]}
            >
                <div className="nodata-modal">
                    <div className={"title"}>
                        {'Your current account does not have any "life" NFTs in an available state. Please try another account or go to Craft life.'}
                    </div>
                    <img className={"nodata-img"} src={getSrc('pve/nodata.png')} alt=""/>
                </div>
            </Modal>
            <Modal
                open={specialVisible}
                className={"end-modal"}
                width={1000}
                getContainer={false}
                footer={null}
                maskClosable={false}
                keyboard={false}
            >
                <div className="submit-modal">
                    <div className={"text-container"}>
                        <div className={"title"}>
                            Christmas Special Level! 500 <img className={"gold-icon"} src={getSrc('pvp/gold.png')} alt=""/> / play
                        </div>
                        <div className={"description"}>{"Note: Tickets are non-refundable for those who haven't played or have failed the game."}</div>
                    </div>
                    <Button isLoading={count !== 0} key="Confirm">
                        <div onClick={() => goToSetOut(activeLevel)}>
                            Confirm
                        </div>
                    </Button>
                </div>
            </Modal>
        </div>
    )
}