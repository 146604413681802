import { Outlet } from "react-router-dom";
import "./index.scss"
import {clickMusic, getSrc, getStr, useCountdownSeconds} from "../../utils/utils";
import { Profile } from "./Connent"
import {Pvp, User} from "../../api"
import { useNavigate, useLocation } from 'react-router-dom';
import {useNetwork, useSwitchNetwork, useAccount, useContractWrite} from 'wagmi';
import { useEffect, useState } from 'react';
import Button from "../../components/Button";
import { useGlobalState } from "../../hook/useGlobalState";
import {Modal} from "antd";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import {wagmigotchiContract} from "../../utils/contract";
import {success} from "concurrently/dist/src/defaults";
import {networkList} from "../../utils/network";


export function Layout() {
    const navigate = useNavigate();
    const location = useLocation();
    const { switchNetwork }: any = useSwitchNetwork()
    const { chain }: any = useNetwork()
    const { address } = useAccount()
    const {
        exitGame,
        setExitGame,
        setIsWhiteList,
        setCoins,
        setUserLifeList,
        setIsNetwork,
        isNetwork,
        bannerVisible,
        setBannerVisible,
        claimVisible,
        setClaimVisible,
        bugVisible,
        setBugVisible,
        isMask,
        network,
        setNetwork
    } = useGlobalState();
    const [ unfinishedVisible, setUnfinishedVisible ] = useState(false)
    const { open } = useWeb3Modal()
    const goToPage = (url: string) => {
        navigate(`/${url}`)
        setUnfinishedVisible(false)
    }
    const handleScroll = (() => {
        const state = {
            scrollTop: 0
        }
        state.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        const element: any = document.getElementById('header-container')
        if (state.scrollTop > 0) {
            element.style.backdropFilter = 'blur(10px)'
        } else {
            element.style.backdropFilter = 'none'
        }
    })
    window.addEventListener('scroll', handleScroll, true)
    useEffect(() => {
        if (chain?.id) {
            const idIndex = networkList.findIndex(item => item.id === chain.id);
            if (idIndex !== -1 && network === idIndex) {
                setIsNetwork(true)
            } else {
                setIsNetwork(false)
            }
        }
    }, [network, chain]);
    const openUrl = (url: string) => {
        window.open(url)
    }
    const claimReward = ((index: number)=> {
      if (!taskList[index].pickStatus && taskList[index].doStatus) {
          const formData = {
              ethAddress: address,
              taskId: taskList[index].taskId
          }
          User.claimReward(formData).then((res: any) => {
              User.getTaskList(address).then((res: any) => {
                  setTaskList(res.data)
              })
              User.getUserCoins(address).then((res: any) => {
                  setCoins(res.data)
              })
          })
      } else {
          goToPage(taskList[index].jumpUrl)
          setClaimVisible(false)
      }
    })
    const [taskList, setTaskList] = useState([] as any)
    const [tokenList, setTokenList] = useState([] as any)
    const [roomId, setRoomId] = useState(0)
    const [tokenId, setTokenId] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        if (address) {
            User.checkUser(address).then((res: any) => {
                setIsWhiteList(res.data)
                if (!res.data) {
                    navigate('/welcome')
                } else {
                    User.getPoint(address, bannerVisible).then((res: any) => {
                        if (res.data) {
                            localStorage.setItem('pvpGuide', '1')
                            localStorage.setItem('pvpStep', '1')
                            localStorage.setItem('pveGuide', '1')
                            localStorage.setItem('pveStep', '1')
                        }
                    })
                    User.getMyLifes(address).then((res: any) => {
                        setUserLifeList(res.data)
                    })
                    User.syncUser(address).then()
                    User.getUserCoins(address).then((res: any) => {
                        setCoins(res.data)
                    })
                    if (!location.pathname.includes('/game-pvp') && !location.pathname.includes('/game-pve') && !location.pathname.includes('/create-lobby')) {
                        Pvp.getGame(address).then((res: any) => {
                            if (res.code === 200) {
                                if (res.data.gameStatus) {
                                    setUnfinishedVisible(true)
                                    setRoomId(res.data.roomId)
                                    setTokenId(res.data.tokenId)
                                }
                            }
                        })
                        User.getOnGoingToken(address).then((res: any) => {
                            setTokenList(res.data)
                            if (res.data.length) {
                                setUnfinishedVisible(true)
                            }
                        })
                        User.checkNotPayBill(address).then((res: any) => {
                            if (res.data) {
                                setLifeDetail(res.data)
                                setModalVisible(true)
                            }
                        })
                    }
                }
            })
        } else {
            const queryString = new URLSearchParams(location.search);
            const name = queryString.get('name');
            if (name) {
                navigate(`/welcome?name=${name}`)
            } else {
                navigate('/welcome');
            }
        }
    }, [address]);
    const [lifeDetail, setLifeDetail] = useState(null as any)
    const { count, countdown } = useCountdownSeconds()

    const { isSuccess, write, isLoading } = useContractWrite({
        ...wagmigotchiContract,
        functionName: 'saveLifeInfo'
    })
    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                // submit bill
                User.payBill(address).then((res: any) => {
                    if (res.code === 200) {
                        countdown(0)
                        setModalVisible(false)
                    }
                })
            }, 3000)
        }
    }, [isSuccess]);
    useEffect(() => {
        if (isLoading) {
            countdown(60)
        } else if (!isSuccess) {
            countdown(0)
        }
    }, [isLoading]);
    const submitContract = () => {
        if (lifeDetail && isNetwork) {
            write({args: [parseInt(lifeDetail.tokenId),lifeDetail.viewRange,lifeDetail.attack,lifeDetail.moveRule]})
        }
    }
    useEffect(() => {
        if (exitGame) {
            User.getOnGoingToken(address).then((res: any) => {
                setTokenList(res.data)
                setExitGame(false)
                if (res.data.length) {
                    setUnfinishedVisible(true)
                }
            })
        }
    }, [exitGame]);
    useEffect(() => {
        if (claimVisible) {
            User.getTaskList(address).then((res: any) => {
                setTaskList(res.data)
            })
        }
    }, [claimVisible]);
    const unfinishedModalCancel = () => {
        setUnfinishedVisible(false)
    }
    const closeBanner = ((event: any) => {
        // event.stopPropagation();
        // User.getPoint(address, true).then((res: any) => {
        //     console.log(res);
        // })
        // setBannerVisible(false)
    })
    useEffect(() => {
        if (bugVisible) {
            clickMusic()
        }
    }, [bugVisible]);
    useEffect(() => {
        if (claimVisible) {
            clickMusic()
        }
    }, [claimVisible]);
    const stars: any = [];
    const counts = 1000;
    const setup = (() => {
        const canvas: any = document.getElementById('star-background');
        const width = canvas.width = window.innerWidth;
        const height = canvas.height = window.innerHeight;
        for (let i = 0; i < counts; i++) {
            stars.push({
                x: Math.random() * width,
                y: Math.random() * height,
                radius: Math.random() * 0.5 + 0.5,
                vx: Math.floor(Math.random() * 10) - 5,
                vy: Math.floor(Math.random() * 10) - 5
            });
        }
    })
    const draw = (() => {
        const canvas: any = document.getElementById('star-background');
        const width = canvas.width = window.innerWidth;
        const height = canvas.height = window.innerHeight;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, width, height);
        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.fillRect(0, 0, width, height);
        ctx.fillStyle = '#ffffff';
        for (let i = 0; i < counts; i++) {
            const star = stars[i];
            ctx.beginPath();
            ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
            ctx.fill();
            star.x += star.vx / 20;
            star.y += star.vy / 20;
            if (star.x < -50) star.x = width + 50;
            if (star.y < -50) star.y = height + 50;
            if (star.x > width + 50) star.x = -50;
            if (star.y > height + 50) star.y = -50;
        }
        requestAnimationFrame(draw);
    })
    useEffect(() => {
        setup();
        draw();
    }, []);
    const [showTopBar, setShowTopBar] = useState(true);

    const handleMouseMove = (e: any) => {
        const topBarThreshold = 50;
        const isTopArea = e.clientY <= topBarThreshold;
        setShowTopBar(isTopArea);
    };

    useEffect(() => {
        // 检查当前路径是否包含特定字符串
        if (location.pathname.includes('/game-pvp') || location.pathname.includes('/game-pve')) {
            // 绑定鼠标移动事件处理函数
            document.addEventListener('mousemove', handleMouseMove);

            // 组件卸载时移除事件监听
            return () => {
                document.removeEventListener('mousemove', handleMouseMove);
            };
        } else {
            setShowTopBar(true)
        }
    }, [location]); // 空依赖数组意味着effect只会在挂载和卸载时运行
    return (
        <section className={isMask ? 'guide-mask' : ''}>
            <canvas id="star-background"></canvas>
            <section>
                <header style={{top: showTopBar ? '0' : '-92px'}}>
                    {
                        bannerVisible ? <div className={"header-url"} onClick={() => openUrl('https://app.questn.com/quest/826334497371566298')}>
                            <svg onClick={closeBanner} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path d="M22.5287 7.91351L7.91258 22.5297" stroke="#F8F0D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M22.5288 22.5297L7.91266 7.91351" stroke="#F8F0D8" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </div>
                            : ''
                    }
                    <div id={"header-container"} className={"header-box"}>
                        <div className={"logo"} onClick={() => goToPage('home')}>
                        </div>
                        <div className={"wallet-right"}>
                            {/*<div onClick={() => setBugVisible(true)} className={"button-item"}>*/}
                            {/*    <div className={"button-icon"}></div>*/}
                            {/*    Report bug*/}
                            {/*</div>*/}
                            {/*<div onClick={() => setClaimVisible(true)} className={"button-item"}>*/}
                            {/*    <div className={"button-icon"}></div>*/}
                            {/*    Quest Reward*/}
                            {/*</div>*/}
                            {
                                address && !isNetwork ? <div onClick={() => switchNetwork(networkList[network].id)}
                                                             className={"network-wrong"}>
                                    Wrong NetWork
                                </div> : ''
                            }
                            <div className={"button-item"} onClick={() => open()}>{address ? getStr(address,4,4) : 'Connect Wallet'}</div>
                        </div>
                    </div>
                </header>
                <main className={bannerVisible ? 'main-banner' : ''}>
                    <Outlet></Outlet>
                </main>
                <Modal
                    open={bugVisible}
                    title="Report a bug"
                    onCancel={() => setBugVisible(false)}
                    className={"bug-visible"}
                    getContainer={false}
                    footer={[
                        <Button key="back">
                            <div className={"back"} onClick={() => setBugVisible(false)}>
                                Back
                            </div>
                        </Button>,
                        <Button key="Confirm">
                            <div onClick={() => openUrl('https://discord.com/channels/1098798014526652476/1137956342158594078')}>
                                Submit
                                <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                            </div>
                        </Button>
                    ]}
                >
                    <div className="content-container">
                        <div className={"content-item"}>
                            1. Critical: This level includes bugs that can cause system crashes, data loss, security vulnerabilities, or render the system unusable. These bugs have a significant impact on system functionality and stability. Reward: <span className={"bold"}>50 USDT</span> each.
                        </div>
                        <div className={"content-item"}>
                            2. High: This level includes bugs that significantly affect system functionality or user experience but do not cause system crashes or render it unusable. Reward: <span className={"bold"}>30 USDT</span> each.
                        </div>
                        <div className={"content-item"}>
                            3. Medium: This level includes functional defects or minor issues that have some impact on system functionality and user experience but do not pose a significant threat to overall system stability. Reward: <span className={"bold"}>15 USDT</span> each.
                        </div>
                        <div className={"content-item"}>
                            4. Low: This level includes minor issues or small interface problems that have minimal impact on system functionality and user experience. These bugs do not significantly affect normal system usage. Reward: <span className={"bold"}>10 USDT</span> each.
                        </div>
                        <div className={"content-item"}>
                            5. Suggestion: This level includes improvement suggestions or non-critical issues that do not directly impact system functionality or user experience. These bugs typically involve additional feature requests, user-friendliness, or performance improvements. Reward: <span className={"bold"}>Discord role</span> assignment.
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={claimVisible}
                    title="Complete the experience to receive rewards"
                    onCancel={() => setClaimVisible(false)}
                    className={"bug-visible"}
                    getContainer={false}
                    footer={[
                        <Button key="back">
                            <div className={"back"} onClick={() => setClaimVisible(false)}>
                                Back
                            </div>
                        </Button>
                    ]}
                >
                    <div className="claim-container">
                        {
                            taskList.length ?
                                taskList.map((item: any, index: number) => (
                                    <div key={index} className={"claim-item"}>
                                        <img className={"claim-icon"} src={getSrc(`layout/claim-icon${index < 2 ? '1' : index < 4 ? '2' : '3'}.png`)} alt=""/>
                                        <div className={"claim-content"}>
                                            {item.taskDesc}
                                        </div>
                                        <div className={"button-container"}>
                                            <div className={"button-item"}>
                                                {item.taskReward} <img src={getSrc('rank/coin-icon.png')} alt=""/>
                                            </div>
                                            <div onClick={() => claimReward(index)} className={`button-item button-second ${item.pickStatus ? 'button-done' : 'button-claim'}`}>
                                                {item.doStatus ? (item.pickStatus ? 'Done' : 'Claim') : 'Go'}
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                    </div>
                </Modal>
                <Modal
                    open={unfinishedVisible}
                    className={"nodata-modal-container"}
                    onCancel={unfinishedModalCancel}
                    getContainer={false}
                    closeIcon={false}
                    maskClosable={false}
                    keyboard={false}
                    footer={[
                        <Button key="Confirm">
                            <div onClick={() => goToPage(roomId ?  `game-pvp/${tokenId}` : `game-pve/${tokenList[0]}`)}>
                                Go
                                <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                            </div>
                        </Button>
                    ]}
                >
                    <div className="nodata-modal">
                        <div className={"title"}>
                            Please return to the game as you still have an ongoing match.
                        </div>
                        <img className={"nodata-img"} src={getSrc('layout/game-background.png')} alt=""/>
                    </div>
                </Modal>
                <Modal
                    open={modalVisible}
                    className={"end-modal"}
                    width={1000}
                    getContainer={false}
                    footer={null}
                    maskClosable={false}
                    keyboard={false}
                >
                    <div className="submit-modal">
                        Please submit the results of your previous match. Once submitted, you may continue playing.
                        <Button isLoading={count !== 0} key="Confirm">
                            <div onClick={submitContract}>
                                Submit
                            </div>
                        </Button>
                    </div>
                </Modal>
            </section>
        </section>
    )
}