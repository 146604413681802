import "./game.scss";
import {clickMusic, getSrc, useCountdownSeconds, extractFirstNumberString} from "../../utils/utils";
import Button from "../../components/Button";
import {DragDropContext, Draggable as DraggableRBdnd, Droppable} from "react-beautiful-dnd";
import {Modal, Select} from "antd";
import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {User} from "../../api";
import {useAccount, useContractWrite} from "wagmi";
import {wagmigotchiContract} from "../../utils/contract";
import {detectMovement, findDisappear} from "../../utils/game";
import Gold from "../../assets/musics/gold.wav";
import Attack from "../../assets/musics/attack.wav";
import {useGlobalState} from "../../hook/useGlobalState";
import GameBackground2 from "../../assets/musics/game-background2.mp3";
import {Helmet} from "react-helmet";
import Typed from "react-typed";

interface GameInfo {
    attack: number
    moveRule: string
    tokenId: string
    toward: string
    viewRange: number
    mapInfo: [string]
    gameInfo: any
    lifeFaceToward: string
}

export function NewGamePVE() {
    const navigate = useNavigate()
    const [editStatus, setEditStatus] = useState(false)
    const [endVisible, setEndVisible] = useState(false)
    const [quitVisible, setQuitVisible] = useState(false)
    const [endStatus, setEndStatus] = useState(0)
    const [submitVisible, setSubmitVisible] = useState(false)
    const {tokenId} = useParams()
    const [gameInfo, setGameInfo] = useState<GameInfo | any>({})
    const valueLabelMap: any = {
        'A': 'My Health ≥ 50% & Encounter enemy',
        'B': 'My Health < 50% & Encounter enemy',
        'C': 'Score Gap > 20 & Encounter enemy',
        'D': 'Score Gap < 20 & Encounter enemy',
        '1': 'Runaway',
        '2': 'Move towards coins',
        '3': 'Attack',
    };
    useEffect(() => {
        if (editStatus) {
            clickMusic()
        }
    }, [editStatus]);
    const {isSuccess, write, isLoading} = useContractWrite({
        ...wagmigotchiContract,
        functionName: 'saveLifeInfo'
    })
    const {count, countdown} = useCountdownSeconds()
    useEffect(() => {
        if (isLoading) {
            countdown(60)
        } else {
            setTimeout(() => {
                countdown(0)
            }, 5000)
        }
    }, [isLoading]);
    const submitContract = () => {
        console.log(isNetwork, tokenId);
        if (tokenId && isNetwork) {
            clickMusic()
            write({args: [parseInt(tokenId), viewRange, attack, options.join(',')]})
        }
    }
    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                // submit bill
                User.payBill(address).then((res: any) => {
                    if (res.code === 200) {
                        setSubmitVisible(false)
                        setEndVisible(true)
                    }
                })
            }, 5000)
        }
    }, [isSuccess]);

    function getLabel(value: string) {
        return valueLabelMap[value];
    }

    const getPosition = async () => {
        const elements = document.querySelectorAll(`.map-container .block-9`)
        elements.forEach((element: any) => {
            if (element) {
                const elementBlock: any = document.querySelector('.block');
                const rect = elementBlock.getBoundingClientRect();
                const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
                const remValue = rect.width / rootFontSize;
                if (nextStep.lifeFaceToward) {
                    if (nextStep.lifeFaceToward === 'UP') {
                        element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b-top.png")'
                    } else if (nextStep.lifeFaceToward === 'DOWN') {
                        element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b.png")'
                    } else if (nextStep.lifeFaceToward === 'LEFT') {
                        element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b-left.png")'
                    } else if (nextStep.lifeFaceToward === 'RIGHT') {
                        element.style.backgroundImage = 'url("https://img.cellula.life/rankback/pc/user-b-right.png")'
                    }
                }
                if (nextStep.toward === 'UP') {
                    element.style.top = parseFloat(element.style.top) ? parseFloat(element.style.top) - remValue + 'rem' : -remValue + 'rem'
                } else if (nextStep.toward === 'DOWN') {
                    element.style.top = parseFloat(element.style.top) ? parseFloat(element.style.top) + remValue + 'rem' : +remValue + 'rem'
                } else if (nextStep.toward === 'LEFT') {
                    element.style.left = parseFloat(element.style.left) ? parseFloat(element.style.left) - remValue + 'rem' : -remValue + 'rem'
                } else if (nextStep.toward === 'RIGHT') {
                    element.style.left = parseFloat(element.style.left) ? parseFloat(element.style.left) + remValue + 'rem' : +remValue + 'rem'
                }
            }
        });
        return true
    }

    const [ghostInfo, setGhostInfo] = useState([] as any)
    const [oldGhostInfo, setOldGhostInfo] = useState([] as any)

    const getCurrentMap = () => {
        if (tokenId) {
            User.getCurrentMap(tokenId).then((res: any) => {
                if (res.code === 200) {
                    if (res.data.gameInfo.gameLevel >= 10) {
                        document.documentElement.style.setProperty('--background-image', `url("${getSrc('pvp/gold-white.png')}")`)
                    }
                    setGameInfo(res.data)
                    if (res.data.gameRound) {
                        setGameRound(res.data.gameRound)
                    }
                    if (res.data.hitRule) {
                        setRulesLog([res.data.hitRule])
                    }
                }
            })
        }
    }

    function checkString(str: string) {
        if (str.includes('3')) return 3;
        if (str.includes('4')) return 4;
        if (str.includes('5')) return 5;
        if (str.includes('6')) return 6;
        return 'No match found';
    }

    useEffect(() => {
        if (ghostInfo.length && oldGhostInfo.length && nextStep.tokenId) {
            ghostInfo.forEach((item: any, index: number) => {
                const newArr: any = [item.ghostArrange, item.ghostLine]
                const oldArr: any = [oldGhostInfo[index].ghostArrange, oldGhostInfo[index].ghostLine]
                const move = detectMovement(oldArr, newArr)
                const element: any = document.getElementsByClassName(`block-0-${item.ghostType}`)
                if (element) {
                    const typeName = item.ghostType
                    const indexGhost = nextStep.ghostFaceToward.findIndex((item: any) => item.type === typeName);
                    if (indexGhost !== -1) {
                        element[0].style.backgroundImage = `url("https://img.cellula.life/rankback/pc/${checkString(typeName)}-${nextStep.ghostFaceToward[indexGhost].turn}.png")`
                    }
                    const elementBlock: any = document.querySelector('.block');
                    const rect = elementBlock.getBoundingClientRect();
                    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
                    const remValue = rect.width / rootFontSize;
                    if (move === 'UP') {
                        element[0].style.top = parseFloat(element[0].style.top) ? parseFloat(element[0].style.top) - remValue + 'rem' : -remValue + 'rem'
                    } else if (move === 'DOWN') {
                        element[0].style.top = parseFloat(element[0].style.top) ? parseFloat(element[0].style.top) + remValue + 'rem' : +remValue + 'rem'
                    } else if (move === 'LEFT') {
                        element[0].style.left = parseFloat(element[0].style.left) ? parseFloat(element[0].style.left) - remValue + 'rem' : -remValue + 'rem'
                    } else if (move === 'RIGHT') {
                        element[0].style.left = parseFloat(element[0].style.left) ? parseFloat(element[0].style.left) + remValue + 'rem' : +remValue + 'rem'
                    }
                }
            })
            setOldGhostInfo(ghostInfo)
        }
    }, [ghostInfo]);
    const [scale, setScale] = useState(1);

    const handleWheel = (e: any) => {
        e.preventDefault();
        setScale(prevScale => {
            let nextScale = prevScale + Math.sign(e.deltaY) * (-0.1);
            if (nextScale < 0.3) {
                nextScale = 0.3;
            } else if (nextScale > 2) {
                nextScale = 2;
            }
            return nextScale;
        });
    };
    const [nextStep, setNextStep] = useState<any>({})
    const [options, setOptions] = useState([])
    const [isClearance, setIsClearance] = useState(false)
    const getNextStep = async () => {
        if (tokenId) {
            await User.getNextStep(tokenId).then(async (res: any) => {
                if (res.code === 200) {
                    if (res.data.gameRound) {
                        setGameRound(res.data.gameRound)
                    }
                    if (res.data.hitRule) {
                        const newRules = rulesLog
                        newRules.unshift(res.data.hitRule)
                        setRulesLog(newRules.slice(0,6))
                    }
                    formatSeconds(360 - res.data.gameRound || 360)
                    const coinsIndex = findDisappear(nextStep.mapInfo ? nextStep.mapInfo : gameInfo.mapInfo, res.data.mapInfo)
                    coinsIndex.forEach((item: any) => {
                        setTimeout(() => {
                            gameInfo.mapInfo[item.i][item.j] = '0'
                            const audioGold = new Audio(Gold);
                            audioGold.play()
                        }, 50)
                    })
                    setGhostHealths(res.data.ghostInfos.map((ghostInfo: any) => ghostInfo.ghostHealth));
                    setGhostInfo(res.data.ghostInfos)
                    if (!previousGhostHealths.length) {
                        setPreviousGhostHealths(res.data.ghostInfos.map((ghostInfo: any) => ghostInfo.ghostHealth))
                    }
                    setNextStep(res.data)
                    setActions(res.data.moveRule.split(','))
                    if (!options.length) {
                        setOptions(res.data.moveRule.split(','))
                    }
                    if (!attack) {
                        setAttack(res.data.attack)
                        setCoinPower(res.data.coinPower)
                        setViewRange(res.data.viewRange)
                    }
                    setHealth(res.data.gameInfo.health)
                } else if (res.code === 307 || res.code === 308) {
                    setSubmitVisible(true)
                    if (!res.data.firstComplete) {
                        setIsClearance(true)
                    }
                    if (res.code === 308) {
                        setEndStatus(1)
                    }
                }
            })
        }
    }

    function formatSeconds(seconds: number): void {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const minutesString = minutes.toString().padStart(2, '0');
        const secondsString = remainingSeconds.toString().padStart(2, '0');
        setTimer(`${minutesString}${secondsString}`)
    }

    const [timer, setTimer] = useState('3600')
    const [actions, setActions] = useState([])
    const [attack, setAttack] = useState(0)
    const [viewRange, setViewRange] = useState(0)
    const [coinPower, setCoinPower] = useState(0)
    const [health, setHealth] = useState(0)
    const [totalHealth, setTotalHealth] = useState(0)
    const [ghostHealths, setGhostHealths] = useState([]);
    const [previousGhostHealths, setPreviousGhostHealths] = useState([]);
    useEffect(() => {
        ghostHealths.forEach((health, index) => {
            if (health === 0 && nextStep.ghostInfos) {
                const element: any = document.getElementsByClassName(`block-0-${nextStep.ghostInfos[index].ghostType}`);
                if (element && element[0]) {
                    element[0].style.display = 'none';
                }
            } else {
                if (previousGhostHealths[index] !== health) {
                    const typeName = ghostInfo[index].ghostType
                    const indexGhost = nextStep.ghostFaceToward.findIndex((item: any) => item.type === typeName);
                    if (indexGhost !== -1) {
                        const elements = document.querySelectorAll(`.map-container .block-0-${nextStep.ghostFaceToward[indexGhost].type}`)
                        elements.forEach((element: any) => {
                            if (element) {
                                if (nextStep.ghostFaceToward[indexGhost].turn === 'UP') {
                                    element.classList.add('moveUpAnimation')
                                    element.addEventListener('animationend', function () {
                                        element.classList.remove('moveUpAnimation');
                                    });
                                } else if (nextStep.ghostFaceToward[indexGhost].turn === 'DOWN') {
                                    element.classList.add('moveDownAnimation')
                                    element.addEventListener('animationend', function () {
                                        element.classList.remove('moveDownAnimation');
                                    });
                                } else if (nextStep.ghostFaceToward[indexGhost].turn === 'LEFT') {
                                    element.classList.add('moveLeftAnimation')
                                    element.addEventListener('animationend', function () {
                                        element.classList.remove('moveLeftAnimation');
                                    });
                                } else if (nextStep.ghostFaceToward[indexGhost].turn === 'RIGHT') {
                                    element.classList.add('moveRightAnimation')
                                    element.addEventListener('animationend', function () {
                                        element.classList.remove('moveRightAnimation');
                                    });
                                }
                            }
                        });
                    }
                }
            }
        });
        setPreviousGhostHealths(ghostHealths);
    }, [ghostHealths]);
    const [isPaused, setIsPaused] = useState(false);
    const timeoutId: any = useRef(null);
    useEffect(() => {
        if (nextStep && nextStep.mapInfo && !isPaused) {
            getPosition().then(async (res: any) => {
                timeoutId.current = setTimeout(async () => {
                    await getNextStep();
                }, 333);
            });
        }

        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [nextStep, isPaused]);
    useEffect(() => {
        if (quitVisible) {
            // setIsPaused(true)
        } else {
            // setIsPaused(false)
        }
    }, [quitVisible]);
    useEffect(() => {
        if (health && health !== totalHealth && nextStep.gameInfo) {
            const elements = document.querySelectorAll(`.map-container .block-9`)
            elements.forEach((element: any) => {
                if (element) {
                    const audioAttack = new Audio(Attack);
                    audioAttack.play()
                    if (nextStep.lifeFaceToward === 'UP') {
                        element.classList.add('moveUpAnimation')
                        element.classList.add('attack-animate-top')
                        element.addEventListener('animationend', function () {
                            element.classList.remove('moveUpAnimation');
                            element.classList.remove('attack-animate-top');
                        });
                    } else if (nextStep.lifeFaceToward === 'DOWN') {
                        element.classList.add('moveDownAnimation')
                        element.classList.add('attack-animate-bottom')
                        element.addEventListener('animationend', function () {
                            element.classList.remove('moveDownAnimation');
                            element.classList.remove('attack-animate-bottom');
                        });
                    } else if (nextStep.lifeFaceToward === 'LEFT') {
                        element.classList.add('moveLeftAnimation')
                        element.classList.add('attack-animate-left')
                        element.addEventListener('animationend', function () {
                            element.classList.remove('moveLeftAnimation');
                            element.classList.remove('attack-animate-left');
                        });
                    } else if (nextStep.lifeFaceToward === 'RIGHT') {
                        element.classList.add('moveRightAnimation')
                        element.classList.add('attack-animate-right')
                        element.addEventListener('animationend', function () {
                            element.classList.remove('moveRightAnimation');
                            element.classList.remove('attack-animate-right');
                        });
                    }
                }
            });
        }
    }, [health]);
    useEffect(() => {
        if (gameInfo.moveRule) {
            setActions(gameInfo.moveRule.split(','))
            if (!options.length) {
                setOptions(gameInfo.moveRule.split(','))
            }
            setAttack(gameInfo.attack)
            setCoinPower(gameInfo.coinPower)
            setViewRange(gameInfo.viewRange)
            setHealth(gameInfo.gameInfo.health)
            setTotalHealth(gameInfo.gameInfo.totalHealth)
            setGhostInfo(gameInfo.ghostInfos)
            gameInfo.ghostInfos.forEach((item: any) => {
                const mapBlocks = document.getElementsByClassName('map-block');
                if (mapBlocks.length > 0 && item.ghostArrange && item.ghostLine) {
                    const elementBlock: any = document.querySelector('.block');
                    const rect = elementBlock.getBoundingClientRect();
                    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
                    const remValue = rect.width / rootFontSize;
                    const firstMapBlock = mapBlocks[0];
                    const newDiv = document.createElement('div');
                    newDiv.className = `block block-0-${item.ghostType}`;
                    newDiv.style.left = `${item.ghostArrange * remValue}rem`;
                    newDiv.style.top = `${item.ghostLine * remValue}rem`;
                    firstMapBlock.appendChild(newDiv);
                }
            })
            if (!oldGhostInfo.length) {
                setOldGhostInfo(gameInfo.ghostInfos)
            }
            getNextStep()
        }
    }, [gameInfo]);
    useEffect(() => {
        getCurrentMap()
    }, []);
    const resetPoint = () => {
        clickMusic()
        setCoinPower(coinPower + (attack - 5) + ((viewRange - 6)))
        setAttack(5)
        setViewRange(6)
    }
    const addAttribute = (type: string) => {
        clickMusic()
        if (type === 'attack' && coinPower > 0) {
            setCoinPower(coinPower - 1)
            setAttack(attack + 1)
        } else if (type === 'viewRange' && coinPower > 1) {
            setCoinPower(coinPower - 2)
            setViewRange(viewRange + 2)
        }
    }
    const subtractAttribute = (type: string) => {
        clickMusic()
        if (type === 'attack' && attack > 5) {
            setCoinPower(coinPower + 1)
            setAttack(attack - 1)
        } else if (type === 'viewRange' && viewRange > 6) {
            setCoinPower(coinPower + 2)
            setViewRange(viewRange - 2)
        }
    }
    const {address} = useAccount()

    const saveAttributePolicy = () => {
        clickMusic()
        const formData = {
            ethAddress: address,
            attack: attack,
            viewRange: viewRange,
            tokenId: tokenId,
            moveRule: options.join(','),
            type: 1,
            gameType: 'pve'
        }
        User.updateAttributePolicy(formData).then((res: any) => {
            if (res.code === 200) {
                setActions(options)
                setEditStatus(false)
            }
        })
    }
    const [newOption, setNewOption] = useState([])
    const optionChange = (type: string, index: number, value: string) => {
        if (type === 'condition') {
            const newOptions: any = [...options]
            newOptions[index] = value + '&' + newOptions[index][2]
            setOptions(newOptions)
        } else {
            const newOptions: any = [...options]
            newOptions[index] = newOptions[index][0] + '&' + value
            setOptions(newOptions)
        }
    }
    const enhance = () => {
        clickMusic()
        navigate('/edit')
    }
    const explore = () => {
        clickMusic()
        if (gameInfo.gameInfo.gameLevel >= 11) {
            navigate('/pve?name=cellula')
        } else {
            navigate('/pve')
        }
    }
    const cancelGame = () => {
        explore()
    }
    const handleOnDragEnd = ((result: any) => {
        if (!result.destination) return;

        const itemsCopy = Array.from(options);
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);
        setOptions(itemsCopy);
    })
    const audioRef = useRef(null as any);

    const startPlaying = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };
    const {setExitGame, isNetwork, setIsMask} = useGlobalState()
    const {pathname} = useLocation()
    const [rulesLog, setRulesLog] = useState([] as any)
    useEffect(() => {
        return () => {
            setExitGame(true)
        }
    }, [pathname]);
    useEffect(() => {
        setTimeout(() => {
            startPlaying()
        }, 3000)
    }, []);
    const [round, setRound] = useState('360' as any)
    const [gameRound, setGameRound] = useState(0)

    useEffect(() => {
        if (gameRound) {
            const rounds = (360-gameRound).toString()
            setRound(rounds.toString().padStart(3, '0'));
        }
    }, [gameRound]);
    const option1 = [
        {value: 'A', label: 'My Health ≥ 50% & Encounter enemy'},
        {value: 'B', label: 'My Health < 50% & Encounter enemy'},
        {value: 'C', label: 'Score Gap > 20 & Encounter enemy'},
        {value: 'D', label: 'Score Gap < 20 & Encounter enemy'}
    ]
    const option2 = [
        {value: '1', label: 'Runaway'},
        {value: '2', label: 'Move towards coins'},
        {value: '3', label: 'Attack'}
    ]
    function getLabel1ByValue(value: string) {
        const item = option1.find(option => option.value === value);
        return item ? item.label : undefined;
    }
    function getLabel2ByValue(value: string) {
        const item = option2.find(option => option.value === value);
        return item ? item.label : undefined;
    }
    const twitterUrl = [
        'https://twitter.com/cellulalifegame',
        'https://twitter.com/BNBCHAIN',
        'https://twitter.com/Dmailofficial',
        'https://twitter.com/UltiverseDAO',
        'https://twitter.com/MetaMerge_xyz',
        'https://twitter.com/Zypher_Games'
    ]
    const openUrl = () => {
        window.open(twitterUrl[gameInfo.gameInfo.gameLevel-11])
    }
    const openUrl2 = () => {
        window.open('https://galxe.com/cellula/campaign/GCrpatte27')
    }
    const [isGuide, setIsGuide] = useState(false)
    const [guideStep, setGuideStep] = useState(1)
    useEffect(() => {
        setIsMask(isGuide)
    }, [isGuide]);
    useEffect(() => {
        const guide = localStorage.getItem('pveGuide')
        const guideStep = localStorage.getItem('pveStep')
        if (guide && guideStep === '4') {
            setIsGuide(true)
            localStorage.removeItem('pveStep')
            localStorage.removeItem('pveGuide')
        }
    }, []);
    return (
        <div className={"game-pve-new-container"} style={{backgroundImage: `url(${gameInfo.gameInfo ? getSrc(`pve-game/background-${gameInfo.gameInfo.gameLevel}.png`) : ''})`}}>
            <Helmet><title>Single Player</title></Helmet>
            {
                isGuide && guideStep === 1 ? <div className={"text-guide"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    'Each game consists of 360 rounds. If your Life is still alive or has earned 240 points by the end of the round, you win the game.'
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setGuideStep(2)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            {
                isGuide && guideStep === 2 ? <div className={"text-guide text-guide2"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    `The top left corner shows the status of the "Ghosts" inside the map, while the top right corner displays the status of your "Life". You can hover over the Ghost's avatar to view specific data.`
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setGuideStep(3)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            {
                isGuide && guideStep === 3 ? <div className={"text-guide text-guide3"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    'The current display shows the active "Action Strategy." Click on "Change Strategy" below to modify the action strategy of your Life.'
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setIsGuide(false)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <audio ref={audioRef} autoPlay loop>
                <source src={GameBackground2} type="audio/mpeg" />
            </audio>
            <div className={"game-pvp-box"}>
                <div className={isGuide && guideStep === 1 || guideStep === 2 ? "pvp-header priority" : 'pvp-header'}>
                    <img onClick={() => setQuitVisible(true)} className={"arrow-icon"} src={getSrc("setout/arrow-left.png")} alt=""/>
                    <div className={"pvp-header-box"}>
                        <div className={isGuide && guideStep !== 2 ? 'ghostInfo guide-mask' : 'ghostInfo'}>
                            {
                                ghostInfo.length ?
                                    ghostInfo.map((item: any, index: any) => (
                                        <div key={index} className={item.ghostHealth === 0 ? 'ghost-item gray-style' : `ghost-item`}>
                                            <div className={"avatar-cover"}>
                                                <img className={item.ghostHealth === 0 ? 'death-style' : ''} src={getSrc(`pve-game/${extractFirstNumberString(item.ghostType)}.png`)} alt="" />
                                            </div>
                                            <svg className="progress-ring" width="80" height="80" viewBox="0 0 118 118">
                                                <circle strokeDasharray={360} strokeDashoffset={360-(360*(item.ghostHealth/item.ghostTotalHealth))} className="progress-ring__circle" stroke="#FF1C1C" strokeWidth="10" fill="transparent" r="57.3" cx="59" cy="59"/>
                                            </svg>
                                            <div className={"attribute"}>
                                                <div className={"view"}>{item.ghostViewRange}</div>
                                                <div className={"attack"}>{item.ghostAttack}</div>
                                            </div>
                                        </div>
                                    ))
                                    : ''
                            }
                        </div>
                        <div className={isGuide && guideStep !== 1 ? 'timer-container guide-mask' : 'timer-container'}>
                            <div className={gameInfo?.gameInfo?.gameLevel > 10 ? 'timer-item timer-item-active' : "timer-item"}>
                                {round[0]}
                            </div>
                            <div className={gameInfo?.gameInfo?.gameLevel > 10 ? 'timer-item timer-item-active' : "timer-item"}>
                                {round[1]}
                            </div>
                            <div className={gameInfo?.gameInfo?.gameLevel > 10 ? 'timer-item timer-item-active' : "timer-item"}>
                                {round[2]}
                            </div>
                        </div>
                        <div
                            className={isGuide && guideStep !== 2 ? 'user-box mine-box guide-mask' : 'user-box mine-box'}>
                            <div className={"user-detail"}>
                                <div className={"avatar-cover"}>
                                    <img src={getSrc('game/user-avatar.png')} alt=""/>
                                </div>
                                <svg className="progress-ring" width="80" height="80" viewBox="0 0 118 118">
                                    <circle strokeDasharray={360} strokeDashoffset={360-(360*(health/totalHealth))}
                                            className="progress-ring__circle" stroke="#06FFDB" strokeWidth="10"
                                            fill="transparent" r="57.3" cx="59" cy="59"/>
                                </svg>
                            </div>
                            {
                                viewRange ? <div className={"detail-data"}>
                                    <div className={"gold-box"}>
                                        <div className={"gold"}>
                                            <img src={getSrc('pvp/gold.png')} alt=""/>
                                        </div>
                                        <span>{nextStep?.nowCoins ? nextStep?.nowCoins : gameInfo.gameInfo.coins}</span>/{gameInfo.gameInfo.gameLevel >= 11 ? 240 : (gameInfo.gameInfo.gameLevel < 5 ? 40 + (gameInfo.gameInfo.gameLevel * 40) : 240)}
                                    </div>
                                    <div className={"attribute-box"}>
                                        <div className={"attack-block"}>{attack}</div>
                                        <div className={"view-block"}>{viewRange/2}</div>
                                    </div>
                                </div> : ''
                            }
                        </div>
                    </div>
                </div>
                <div className={"pvp-main"}>
                    <div className={"map-background"} style={{backgroundImage: `url(${gameInfo.gameInfo ? getSrc(`setout/map${gameInfo.gameInfo.gameLevel}.png`) : ''})`}}></div>
                    <div className={"map-container"}>
                        { gameInfo.mapInfo ? gameInfo.mapInfo.map((item: any, index: number) => (
                            <div key={index} className="map-block">
                                {
                                    item.map((items: any, indexs: number) => (
                                        <div key={indexs} className={`block block-${['0', '1', '2', '9'].includes(items) ? items : '0'} ${items === '1' ? `wall-${gameInfo.gameInfo.gameLevel}` : ''}`}></div>                                                    ))
                                }
                            </div>
                        )) : '' }
                    </div>
                    <div className={isGuide && guideStep === 3 ? 'log-container priority' : 'log-container'}>
                        <div className={"log-box"}>
                            {
                              rulesLog.map((item: any, index:number) => (
                                  <div key={index} className={index === 0 && nextStep?.hitRule ? 'log-item log-item-active' : 'log-item'}>
                                    <div className={"cover"}>
                                      <img src={getSrc(`pvp/strategy-${item[2]}.png`)} alt=""/>
                                    </div>
                                    <div className={"right-container"}>
                                      <div className={"title"}>{getLabel2ByValue(item[2])}</div>
                                      <div className={"description"}>{getLabel1ByValue(item[0])}</div>
                                    </div>
                                  </div>
                              ))
                            }
                        </div>
                    </div>
                    <div className={isGuide && guideStep === 3 ? 'change-button priority' : 'change-button'}>
                        <Button mid={true}>
                            <div onClick={() => setEditStatus(true)}>
                                Change Strategy
                            </div>
                        </Button>
                    </div>
                    {
                        editStatus && attack ? <div className="edit-container">
                                <div className={"edit-box"}>
                                    <img onClick={() => setEditStatus(false)} className={"close-img"}
                                         src={getSrc('pvp/close.png')} alt=""/>
                                    <div className="right-header">
                                        <div className="life-detail">
                                            <div className="cover-left">
                                                <div className="tokenId">
                                                    TokenId: <span>{tokenId}</span>
                                                </div>
                                                <div className="cover-box">
                                                    <img src={getSrc('game/user-avatar.png')} alt=""/>
                                                    <div className={"light-block block-detail"}>{coinPower}</div>
                                                    {coinPower >= coinPower + (attack - 5) + ((viewRange - 6)) ? '' :
                                                        <div onClick={resetPoint} className={"refresh"}></div>}
                                                </div>
                                                <div className="boold-container">
                                                    <div className="boold-data-container">
                                                        <div className="data">{health}/{totalHealth}</div>
                                                        <div className="progress-box">
                                                            <div className="progress-rate"
                                                                 style={{width: ((health / totalHealth) * 100) + '%'}}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cover-right">
                                                <div className="block-back">
                                                    <div className="block-header">
                                                        2 <img src={getSrc('setout/smail-light.png')} alt=""/>
                                                        / + 1
                                                    </div>
                                                    <div className={"view-block block-detail block-function"}>
                                                        <span>{viewRange / 2}</span>
                                                        <div className={"add-block"}
                                                             onClick={() => addAttribute('viewRange')}></div>
                                                        <div className={"add-block subtract-block"}
                                                             onClick={() => subtractAttribute('viewRange')}></div>
                                                    </div>
                                                </div>
                                                <div className="block-back">
                                                    <div className="block-header">
                                                        1 <img src={getSrc('setout/smail-light.png')} alt=""/>
                                                        / + 1
                                                    </div>
                                                    <div className={"attack-block block-detail block-function"}>
                                                        <span>{attack}</span>
                                                        <div className={"add-block"}
                                                             onClick={() => addAttribute('attack')}></div>
                                                        <div className={"add-block subtract-block"}
                                                             onClick={() => subtractAttribute('attack')}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="edit-strategy-box">
                                        <div className={"title-edit"}>
                                            Action Strategy
                                        </div>
                                        <div className={"table-container"}>
                                            <div className="label">Order</div>
                                            <div className="label">Condition</div>
                                            <div className="label">Action</div>
                                        </div>
                                        <div className={"option-container"}>
                                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                                <Droppable droppableId="list">
                                                    {(provided: any) => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                                            {options.map((item, index) => (
                                                                <DraggableRBdnd key={index} draggableId={`${item}+${index}`}
                                                                                index={index}>
                                                                    {(provided: any) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            key={index}
                                                                            className="option-item"
                                                                        >
                                                                            <img src={getSrc('setout/move-img.png')}
                                                                                 alt=""/>
                                                                            <div className="label-index">{index + 1}</div>
                                                                            <div className={"select-container"}>
                                                                                <div className="select-item">
                                                                                    <Select
                                                                                        value={item[0]}
                                                                                        onChange={(value) => optionChange('condition', index, value)}
                                                                                        placeholder={"Make your selection"}
                                                                                        className={item[0] ? 'value-item' : 'value-item no-value'}
                                                                                        onFocus={clickMusic}
                                                                                        options={[
                                                                                            {
                                                                                                value: 'A',
                                                                                                label: 'My Health ≥ 50% & Encounter enemy'
                                                                                            },
                                                                                            {
                                                                                                value: 'B',
                                                                                                label: 'My Health < 50% & Encounter enemy'
                                                                                            },
                                                                                            {
                                                                                                value: 'C',
                                                                                                label: 'Score Gap > 20 & Encounter enemy'
                                                                                            },
                                                                                            {
                                                                                                value: 'D',
                                                                                                label: 'Score Gap < 20 & Encounter enemy'
                                                                                            },
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                                <div className="select-item">
                                                                                    <Select
                                                                                        value={item[2]}
                                                                                        onChange={(value) => optionChange('action', index, value)}
                                                                                        placeholder={"Make your selection"}
                                                                                        onFocus={clickMusic}
                                                                                        className={item[0] ? 'value-item' : 'value-item no-value'}
                                                                                        options={[
                                                                                            {value: '1', label: 'Runaway'},
                                                                                            {
                                                                                                value: '2',
                                                                                                label: 'Move towards coins'
                                                                                            },
                                                                                            {value: '3', label: 'Attack'},
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </DraggableRBdnd>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>

                                            <div className="edit-button-container">
                                                <Button small={true}>
                                                    <div onClick={() => setEditStatus(false)}>
                                                        Back
                                                    </div>
                                                </Button>
                                                <Button small={true}>
                                                    <div onClick={saveAttributePolicy}>
                                                        Confirm
                                                        <img className="arrow-icon"
                                                             src={getSrc('components/button-arrow.png')} alt=""/>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        gameInfo?.gameInfo?.gameLevel > 10 ?
                            <div className={"social-media"}>
                                <img onClick={openUrl} src={getSrc('map-img/x-icon.png')} alt=""/>
                                <img onClick={openUrl2} src={getSrc('map-img/galxe.png')} alt=""/>
                            </div>
                            :''
                    }
                </div>
            </div>
            <Modal
                open={endVisible}
                className={"end-modal"}
                width={740}
                getContainer={false}
                footer={[
                    <Button key="Confirm">
                        <div onClick={explore}>
                            Map
                            <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                        </div>
                    </Button>
                ]}
            >
                <div className="end-modal">
                    {
                        endStatus ? <div className={"end-top"}>
                                <div className={"logo-end"}></div>
                                <div className={"detail-data"}>
                                    <div className="detail-tiem">
                                        <img src={getSrc('game/coin.png')} alt=""/>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                             fill="none">
                                            <path d="M20.999 6.99805L6.99902 20.998" stroke="#FFE500" strokeWidth="4.48"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M21.001 20.998L7.00098 6.99805" stroke="#FFE500" strokeWidth="4.48"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        {
                                            gameInfo?.gameInfo.gameLevel >= 11 ? (nextStep?.nowCoins >= 240 ? 240 : Math.floor(nextStep?.nowCoins * 0.3)) :
                                                (nextStep?.nowCoins >= (gameInfo.gameInfo.gameLevel < 5 ? 40 + (gameInfo.gameInfo.gameLevel * 40) : 240) ? (gameInfo.gameInfo.gameLevel < 5 ? 40 + (gameInfo.gameInfo.gameLevel * 40) : 240) : Math.floor(nextStep?.nowCoins * 0.3))
                                        }
                                    </div>
                                    {
                                        gameInfo?.gameInfo.gameLevel >= 11 || isClearance ? '' :
                                            <div className="detail-tiem">
                                            <img src={getSrc('game/lock.png')} alt=""/>
                                            <span>:</span>1-{nextStep?.gameInfo?.gameLevel + 1}
                                        </div>
                                    }
                                </div>
                            </div>
                            : <div className={"end-top end-failed"}>
                                <div className={"logo-failed"}></div>
                                <div className={"failed-description"}>
                                    Your life with Token ID: <span>{tokenId}</span> has died, the game is over!
                                </div>
                            </div>
                    }
                </div>
            </Modal>
            <Modal
                open={submitVisible}
                className={"end-modal"}
                width={1000}
                getContainer={false}
                footer={null}
            >
                <div className="submit-modal">
                    The battle has ended. Please submit the game results to proceed with the settlement.
                    <Button isLoading={count !== 0} key="Confirm">
                        <div onClick={submitContract}>
                            Submit
                        </div>
                    </Button>
                </div>
            </Modal>
            <Modal
                open={quitVisible}
                className={"end-modal"}
                width={1000}
                getContainer={false}
                footer={null}
            >
                <div className="submit-modal">
                    Quitting the game prematurely may lead to failure of the current game. Are you sure you want to
                    quit?
                    <div className={"button-container"}>
                        <Button mid={true} key="Confirm">
                            <div onClick={cancelGame}>
                                Quit
                            </div>
                        </Button>
                        <Button mid={true} key="Confirm">
                            <div onClick={() => setQuitVisible(false)}>
                                Back
                            </div>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}