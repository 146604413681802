import "./index.scss"
import {clickMusic, getSrc, getStr} from "../../utils/utils";
import {useState, useEffect} from "react";
import { User } from "../../api"
import { useAccount } from "wagmi";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
export function Rank() {
    const navigate  = useNavigate();
    const { address } = useAccount()
    const [rankList, setRankList] = useState<any>([])
    const [myRank, setMyRank] = useState<any>(null)
    const goBack = () => {
        clickMusic()
        navigate(-1);
    }
    useEffect(() => {
        if (address) {
            User.getCoinsRank(address).then((res: any) => {
                if (res.code === 200) {
                    setMyRank(res.data.myRank)
                    if (res.data.userCoinsInfos.length) {
                        setRankList(res.data.userCoinsInfos)
                    }
                }
            })
        }
    }, [address]);
    return (
        <div className={"rank-container"}>
            <Helmet><title>Leaderboard</title></Helmet>
            <img onClick={goBack} className={"back"} src={getSrc('setout/arrow-left.png')} alt=""/>
            { rankList.length ?
                <div className={"rank-main"}>
                <div className={"mine-item"}>
                    <div className={"ranking"}>
                        My Rank
                    </div>
                    <div className={"ranking"}>
                        {myRank.rank}
                    </div>
                    <div className={"ranking"}>
                        {myRank.coins}
                    </div>
                </div>
                <div className={"description"}>
                    Only show the top 30, updated every 5 minutes.
                </div>
                <div className={"label-container"}>
                    <div className={"label-item"}>
                        <img src={getSrc('rank/rank-icon.png')} alt=""/>
                        <span>Rank</span>
                    </div>
                    <div className={"label-item"}>
                        <span>Address</span>
                    </div>
                    <div className={"label-item"}>
                        <img src={getSrc('rank/coin-icon.png')} alt=""/>
                        <span>Coin</span>
                    </div>
                </div>
                <div className={"data-container"}>
                    {
                        rankList.map((item: any, index: number) => (
                            <div key={index} className={"data-item"}>
                                <div className={"value-item"}>
                                    <div className={"value-box"}>
                                        {
                                            index <= 2 ? <img className={"rank-icon"} src={getSrc(`rank/rank-${index+1}.png`)} alt=""/> : index + 1
                                        }
                                    </div>
                                </div>
                                <div className={"value-item"}>
                                    {/*<div className={"cover-box"}>*/}
                                    {/*    /!*<img className={"cover"} src="#" alt=""/>*!/*/}
                                    {/*</div>*/}
                                    {getStr(item.ethAddress,4,4)}
                                </div>
                                <div className={"value-item"}>
                                    {item.coins}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            : ''
            }
        </div>
    )
}