import "./index.scss"
import { useAccount, useConnect } from "wagmi";
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useNavigate } from "react-router-dom";
import {useEffect} from "react";
import ActivitiesButton from "../../components/Activities-Button"
import Button from "../../components/Button"
import {message} from "antd";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import {Helmet} from "react-helmet";
import {clickMusic, getSrc} from "../../utils/utils";
import {base} from "viem/chains";


export function Welcome() {
    const { address, connector } = useAccount()
    const navigate = useNavigate()
    const { open } = useWeb3Modal()

    const checkConnect = (() => {
        clickMusic()
        if (address) {
            const queryString = new URLSearchParams(location.search);
            const name = queryString.get('name');
            if (name) {
                navigate(`/pve?name=${name}`)
            } else {
                navigate('/home')
            }
        } else {
            open()
            // message.info('Please connect your wallet.')
        }
    })
    const { connect, isSuccess } = useConnect({
        connector: new InjectedConnector(),
    })
    const openUrl = ((url: string) => {
        clickMusic()
        window.open(url)
    })
    return (
        <div className="welcome-container">
            <Helmet><title>Welcome</title></Helmet>
            <div onClick={checkConnect} className={"button"}>
                <Button>
                    <div>
                        <div className={"controller-icon"}></div>
                        Start Game
                    </div>
                </Button>
            </div>
            <div onClick={() => openUrl('https://twitter.com/cellulalifegame')} className={"twitter-icon"}></div>
            <div onClick={() => openUrl('https://discord.gg/fzDF544GFP')} className={"discord-icon"}></div>
        </div>
    )
}