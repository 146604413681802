import {useEffect, useState} from "react";
import {getSrc, getStr} from "../../utils/utils";
import {useNavigate} from "react-router-dom";
import {useAccount} from "wagmi";
import {Pvp} from "../../api";

export function Leaderboard() {
    const navigate = useNavigate()
    const levels = new Array(10).fill(null)
    const {address} = useAccount()
    const [myPlayerRank, setMyPlayerRank] = useState({} as any)
    const [playerRank, setPlayerRank] = useState([] as any)
    const [lifeRank, setLifeRank] = useState([] as any)
    useEffect(() => {
        // const items = document.querySelectorAll('.leaderboard-container > div');
        // items.forEach((item: any, index) => {
        //     item.style.transform = `translate(-${index * 0.4010416}rem, -${index * 0.109375}rem)`;
        // });
    }, []);
    const goToPvp = () => {
        navigate('/pvp-setout')
    }
    useEffect(() => {
        Pvp.getLifeRank().then((res: any) => {
            if (res.code === 200 && res.data.length) {
                setLifeRank(res.data)
            }
        })
        if (address) {
            Pvp.getPlayerRank(address).then((res: any) => {
                if (res.code === 200) {
                    setMyPlayerRank(res.data.myPlayerRank)
                    setPlayerRank(res.data.playerRankList)
                }
            })
        }
    }, [address]);
    const openPage = (url: string) => {
        window.open(url)
    }
    return (
        <div className={'leaderboard-container'}>
            {/*{*/}
            {/*    levels.map((item: any) => (*/}
            {/*        <div key={item} className="grid-container">*/}
            {/*            {*/}
            {/*                levels.map((item: any) => (*/}
            {/*                    <div key={item} className="diamond-tile"></div>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    ))*/}
            {/*}*/}
            <div className={'main'}>
                <div className={"top-header"}>
                    <div className={"left-container"}>
                        <div className={'title-banner'}></div>
                        <div className={"text-container"}>
                            <div className={"title"}>
                                Event Duration:<br/>
                                <span>2024/02/06 - 2024/02/29</span>
                            </div>
                            <div className={"title"}>
                                Event Rules:<br/>
                                <div className={"description"}>
                                    1. Eligibility : All Cellula Life users can participate. <br/>
                                    2. Tournament Schedule : Ranking based on the number of victories. <br/>
                                    3. Honor Rewards : Top10 Life ------- Split $500 USDT cash <div
                                    onClick={() => openPage('https://galxe.com/cellula/campaign/GCAyutwsiE')}
                                    className={'link-icon'}></div> <br/>
                                    <span className={'block'}>1111111111111111111111111</span>Top 10 player ------- OG
                                    discord role <div
                                    onClick={() => openPage('https://galxe.com/cellula/campaign/GCwEbtUCW6')}
                                    className={'link-icon'}></div><br/>
                                    <span className={'block'}>1111111111111111111111111</span>Top 30 player -------
                                    Top30 discord role <div
                                    onClick={() => openPage('https://galxe.com/cellula/campaign/GCzUbtU9hX')}
                                    className={'link-icon'}></div><br/>
                                    <span className={'block'}>1111111111111111111111111</span>All participant -------
                                    Pacman NFT <div
                                    onClick={() => openPage('https://galxe.com/cellula/campaign/GCTXqtUdnQ')}
                                    className={'link-icon'}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={goToPvp} className={"right-container"}>Join Now</div>
                </div>
                <div className="footer-container">
                    <div className="left-container">
                        <div className={"text"}>Life Leaderboard</div>
                        <div className={"left-box"}>
                            <div className="title">
                                <div className={'title-item'}>Life</div>
                                <div className={'title-item'}>Token ID</div>
                                <div className={'title-item'}>Wins</div>
                                <div className={'title-item'}>Total matches</div>
                                <div className={'title-item'}>Win rate</div>
                            </div>
                            <div className="life-container">
                                {
                                    lifeRank.map((item: any, index: number) => (
                                        <div key={item} className="life-item">
                                            <div className={"label"}>
                                                {index + 1}
                                            </div>
                                            <div className={"cover-container"}>
                                                <div className={"cover"}>
                                                    <img src={item.image} alt=""/>
                                                </div>
                                                <div className={"health"}>{item.health}</div>
                                            </div>
                                            <div className={"data-container"}>
                                                <div className={"data-item"}>
                                                    {item.tokenId}
                                                </div>
                                                <div className={"data-item"}>
                                                    {item.winCount}
                                                </div>
                                                <div className={"data-item"}>
                                                    {item.gameCount}
                                                </div>
                                                <div className={"data-item"}>
                                                    {item.winRate}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="right-container">
                        <div className={"text"}>Player Leaderboard</div>
                        {
                            myPlayerRank.gameCount ?
                                <div className={"mine-item"}>
                                    <div className={"data-container"}>
                                        <div className={"data-item"}>
                                            Mine
                                        </div>
                                        <div className={"data-item"}>
                                            {getStr(address, 4, 4)}
                                        </div>
                                        <div className={"data-item"}>
                                            {myPlayerRank.winCount}
                                        </div>
                                        <div className={"data-item"}>
                                            {myPlayerRank.gameCount}
                                        </div>
                                        <div className={"data-item"}>
                                            {myPlayerRank.winRate}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={"mine-item nodata"}>
                                    <div className={"data-container"}>
                                        <div className={"data-item"}>
                                            Mine
                                        </div>
                                        <div className={"data-item"}>
                                            Not participated
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className={"left-box"}>
                            <div className="title">
                                <div className={'title-item'}></div>
                                <div className={'title-item'}>address</div>
                                <div className={'title-item'}>Wins</div>
                                <div className={'title-item'}>Total matches</div>
                                <div className={'title-item'}>Win rate</div>
                            </div>
                            <div className="life-container">
                                {
                                    playerRank.map((item: any, index: number) => (
                                        <div key={item} className="life-item">
                                            <div className={"data-container"}>
                                                <div className={"label"}
                                                     style={{backgroundImage: `url(${index <= 2 ? getSrc(`rank/rank-${index + 1}.png`) : ''})`}}>
                                                    {index >= 3 ? index + 1 : ''}
                                                </div>
                                                <div className={"data-item"}>
                                                    {item.ethAddress}
                                                </div>
                                                <div className={"data-item"}>
                                                    {item.winCount}
                                                </div>
                                                <div className={"data-item"}>
                                                    {item.gameCount}
                                                </div>
                                                <div className={"data-item"}>
                                                    {item.winRate}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}