import "./newSelectionLife.scss"
import { LifeItem } from "./lifeItem";
import {getSrc, debounce, clickMusic} from "../../utils/utils";
import Button from "../../components/Button";
import {useEffect, useRef, useState} from 'react';
import {useGlobalState} from "../../hook/useGlobalState";
// import { wagmigotchiContract } from '../../utils/contract'
import {useAccount, useContractRead} from 'wagmi';
import {User} from "../../api"
import {message, Modal} from "antd";
import Typed from "react-typed";
import {cellulaUrl} from "../../utils/contract";
interface SelectionLifeProps {
    userLifeList: any[];
    activeIndex: number;
    nextStep: () => void;
    setActiveIndexFun: (newIndex: number) => void
    refreshData: () => void
}
interface LifeInfo {
    viewRange?: number;
    attack?: number;
    moveRule?: string;
}

export function NewSelectionLife({
                                     userLifeList,
                                     activeIndex,
                                     nextStep,
                                     setActiveIndexFun,
                                     refreshData
                                 }: SelectionLifeProps) {
    const [lifeInfo, setLifeInfo] = useState<LifeInfo>({})
    const [modalVisible, setModalVisible] = useState(false)
    const [errorVisible, setErrorVisible] = useState(false)
    const {address} = useAccount()
    const handleIndexChange = (newIndex: number) => {
        setActiveIndexFun(newIndex)
    }

    // const { data: readData } = useContractRead({
    //     ...wagmigotchiContract,
    //     functionName: 'lifeInfo',
    //     args: [parseInt(userLifeList[activeIndex]?.tokenId)]
    // })
    const [skillPoints, setSkillPoints] = useState(0)
    // useEffect(() => {
    //     const data: any = readData
    //     setLifeInfo(data)
    //     User.getExchangePower(userLifeList[activeIndex]?.tokenId).then((res: any) => {
    //         if (res.code === 200) {
    //             setSkillPoints(res.data)
    //         }
    //     })
    // }, [readData]);
    const {setIsMask, setCoins, coins} = useGlobalState()
    const confirmNext = () => {
        clickMusic()
        if (!userLifeList[activeIndex]?.status) {
            nextStep()
        } else {
            message.info('Waiting For Respawn.')
        }
    }
    const scrollAmount = 600;
    const scrollDirection = ((direction: string) => {
        const levelContainer = document.querySelector('.life-container');
        if (levelContainer) {
            clickMusic()
            if (direction === 'right') {
                levelContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            } else {
                levelContainer.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            }
        } else {
            console.error('.level-container not found');
        }
    })
    const confirmNextDebounced = debounce(confirmNext, 500, null)
    const containerRef = useRef(null as any);

    const handleWheel = (event: any) => {
        // 在非passive事件监听器内，可以调用preventDefault
        event.preventDefault();
        if (containerRef.current) {
            containerRef.current.scrollLeft += event.deltaY;
        }
    };

    useEffect(() => {
        // 组件挂载时添加事件监听器
        const container = containerRef.current;
        container.addEventListener('wheel', handleWheel, { passive: false });

        // 组件卸载时移除事件监听器
        return () => {
            container.removeEventListener('wheel', handleWheel);
        };
    }, []); // 空数组保证了事件监听器只绑定一次
    const [isGuide, setIsGuide] = useState(false)
    useEffect(() => {
        setIsMask(isGuide)
    }, [isGuide]);
    useEffect(() => {
        const guide = localStorage.getItem('pveGuide')
        const guideStep = localStorage.getItem('pveStep')
        if (guide && guideStep === '2') {
            setIsGuide(true)
            localStorage.setItem('pveStep', '3')
        }
    }, []);
    const reviveLife = () => {
        if (coins >= 200) {
            const data = {
                ethAddress: address,
                tokenId: userLifeList[activeIndex].tokenId
            }
            User.reviveLife(data).then((res: any) => {
                if (res.code === 200) {
                    setModalVisible(false)
                    getUserCoins()
                    refreshData()
                } else {
                    setErrorVisible(true)
                }
            })
        } else {
            setModalVisible(false)
            setErrorVisible(true)
        }
    }
    const getUserCoins = () => {
        User.getUserCoins(address).then((res: any) => {
            setCoins(res.data)
        })
    }
    const openUrl = (url: string) => {
        window.open(url)
    }
    return (
        <div className={'new-selection-life'}>
            {
                isGuide ? <div className={"text-guide"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    'Choose the Life you want to deploy here. The higher its health, the greater the chances of winning.'
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setIsGuide(false)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <div className={"top-container"}>
                <div className={"top-box"}>
                    <div className={"tokenId"}>
                        TokenID: <span className={"bold"}>{userLifeList[activeIndex]?.tokenId}</span>
                    </div>
                    <div className={"avatar-container"}>
                        <img src={userLifeList[activeIndex]?.image} alt=""/>
                    </div>
                    <div className={"point"}>{userLifeList[activeIndex]?.gameLifeEntityInfo.coinPower}</div>
                    <div className={"view"}>{userLifeList[activeIndex]?.gameLifeEntityInfo.viewRange / 2}</div>
                    <div className={"attack"}>{userLifeList[activeIndex]?.gameLifeEntityInfo.attack}</div>
                    <div className="boold-data-container">
                        <div className={"health-block"}>{userLifeList[activeIndex]?.health}</div>
                        <div className="progress-box">
                            <div className="progress-rate"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isGuide ? 'life-main priority' : 'life-main'}>
                <div className="life-box">
                    <div className={"life-container"} ref={containerRef}>
                        {
                            userLifeList.map((item, index) => (
                                <div onClick={() => setActiveIndexFun(index)} key={index} className={activeIndex === index ? "life-item life-item-active" : "life-item"}>
                                    <div className={"health"}>{item.health}</div>
                                    <div className={"token-id"}>{item.tokenId}</div>
                                    <div className={"cover-container"}>
                                        <div className={!item.status ? "cover-box" : 'death-paw2 cover-box'}>
                                            <img className={!item.status ? "avatar" : "avatar death-style"} src={item.image} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className={"arrow-left"} onClick={() => scrollDirection('left')}></div>
                    <div className={"arrow-right"} onClick={() => scrollDirection('right')}></div>
                </div>
            </div>
            <img className={userLifeList[activeIndex]?.status ? 'death-style avatar-banner' : 'avatar-banner'} src={userLifeList[activeIndex]?.image} alt=""/>
            <div className={"button-next"}>
                <Button>
                    {
                        userLifeList[activeIndex]?.status ?
                            <div onClick={() => setModalVisible(true)}>
                                Revive
                            </div>
                            :
                            <div onClick={confirmNextDebounced}>
                                Confirm
                                <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                            </div>
                    }
                </Button>
            </div>
            <Modal
                open={modalVisible}
                className={"end-modal"}
                getContainer={false}
                footer={null}
                maskClosable={false}
                keyboard={false}
            >
                <div className="submit-modal">
                    <div className={"text-container"}>
                        Do you want to resurrect {userLifeList[activeIndex].tokenId} ?
                        Resurrection will cost 200 <img src={getSrc('game/gold.png')} alt=""/>
                    </div>
                    <div className={"button-container"}>
                        <Button small={true}>
                            <div onClick={() => setModalVisible(false)}>
                                Back
                            </div>
                        </Button>
                        <Button small={true}>
                            <div onClick={reviveLife}>
                                Yes
                            </div>
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={errorVisible}
                className={"end-modal"}
                getContainer={false}
                footer={null}
                maskClosable={false}
                keyboard={false}
            >
                <div className="submit-modal">
                    Insufficient gold coins, please synthesize a new life.
                    <div className={"button-container"}>
                        <Button small={true}>
                            <div onClick={() => setErrorVisible(false)}>
                                Back
                            </div>
                        </Button>
                        <Button small={true}>
                            <div onClick={() => openUrl(cellulaUrl)}>
                                Craft
                            </div>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}