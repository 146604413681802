import React, { useState, FC, ReactNode } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

interface GlobalStateProviderProps {
    children: ReactNode;
}

export const GlobalStateProvider: FC<GlobalStateProviderProps> = ({ children }) => {
    const [isWhiteList, setIsWhiteList] = useState(true);
    const [coins, setCoins] = useState(0);
    const [exitGame, setExitGame] = useState(false)
    const [userLifeList, setUserLifeList] = useState([])
    const [isNetwork, setIsNetwork] = useState(true)
    const [bannerVisible, setBannerVisible] = useState(false)
    const [bugVisible, setBugVisible] = useState(false)
    const [claimVisible, setClaimVisible] = useState(false)
    const [roomId, setRoomId] = useState(0)
    const [isMask, setIsMask] = useState(false)
    const [network, setNetwork] = useState(3)
    return (
        <GlobalStateContext.Provider
            value={{
                isWhiteList,
                setIsWhiteList,
                coins,
                setCoins,
                exitGame,
                setExitGame,
                userLifeList,
                setUserLifeList,
                isNetwork,
                setIsNetwork,
                setBannerVisible,
                bannerVisible,
                setBugVisible,
                bugVisible,
                claimVisible,
                setClaimVisible,
                roomId,
                setRoomId,
                isMask,
                setIsMask,
                network,
                setNetwork
            }}>
            {children}
        </GlobalStateContext.Provider>
    );
};