import "./selectionStrategy.scss"
import {clickMusic, getSrc, useCountdownSeconds} from "../../utils/utils";
import {Select, Modal, Popover, message} from "antd"
import Button from "../../components/Button";
import { SetStateAction, useEffect, useState } from "react";
import { User } from '../../api';
import { useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { wagmigotchiContract } from "../../utils/contract"
import { useAccount, useContractWrite } from 'wagmi';
import { useGlobalState } from "../../hook/useGlobalState";
import useDebounce from "../../utils/debounce"
import useThrottle from '../../utils/throttle';
import Typed from "react-typed";
interface SelectionStrategyProps {
    lifeDetail: {
        gameLifeEntityInfo: {
            attack: number
            coinPower: number
            moveRule: string
            tokenId: string
            viewRange: number,
            pvpMoveRule: string
        },
        health: number,
        image: string,
        status: number,
        tokenId: string
    },
    refreshData: () => void
}
const condition: any = [
    {value: 'A', label: 'My Health ≥ 50% & Encounter enemy'},
    {value: 'B', label: 'My Health < 50% & Encounter enemy'},
    {value: 'C', label: 'Score Gap > 20 & Encounter enemy'},
    {value: 'D', label: 'Score Gap < 20 & Encounter enemy'},
    {value: 'G', label: 'Discover prop'},
]
const action: any = {
    'A': [{value: '1', label: 'Runaway'},
        {value: '2', label: 'Move towards coins'},
        {value: '3', label: 'Attack'}],
    'B': [{value: '1', label: 'Runaway'},
    {value: '2', label: 'Move towards coins'},
    {value: '3', label: 'Attack'}],
    'C': [{value: '1', label: 'Runaway'},
        {value: '2', label: 'Move towards coins'},
        {value: '3', label: 'Attack'}],
    'D': [{value: '1', label: 'Runaway'},
        {value: '2', label: 'Move towards coins'},
        {value: '3', label: 'Attack'}],
    'G': [
        {value: '4', label: 'Move towards prop'},
        {value: '5', label: 'Reverse movement'},
    ]
}
export function SelectionStrategy({lifeDetail, refreshData}: SelectionStrategyProps) {
    const navigator = useNavigate()
    const { address } = useAccount()
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = useState(lifeDetail)
    const [recommendValue, setRecommendValue] = useState(0)
    const [options, setOptions] = useState(lifeDetail.gameLifeEntityInfo.pvpMoveRule.split(','))
    const setRecommend = ((val: SetStateAction<number>) => {
        setRecommendValue(val)
    })
    const [gameLifeEntityInfo, setGameLifeEntityInfo] = useState(lifeDetail.gameLifeEntityInfo)
    const { count, countdown } = useCountdownSeconds()
    const { level } = useParams()
    const pointList = [200,300,450,675,1000,1500,2250,3375,5000,7500]
    const coins = [1000,2000,4000]
    const actionsValues = [['A&3','C&2','B&1'],['A&1','A&2','B&1'],['A&1','C&2','D&2']]
    const recommendLists = [
        {
            attributes: [4,8],
            title: 'Offensive',
            condition: ['My Health ≥ 50% & Encounter enemy', 'Score Gap > 20 & Encounter enemy', 'My Health < 50% & Encounter enemy'],
            action: ['Attack','Move towards coins','Runaway']
        },
        {
            attributes: [4,8],
            title: 'Evasive',
            condition: ['My Health ≥ 50% & Encounter enemy', 'My Health ≥ 50% & Encounter enemy', 'My Health < 50% & Encounter enemy'],
            action: ['Runaway','Move towards coins','Runaway']
        },
        {
            attributes: [4,8],
            title: 'Collecting',
            condition: ['My Health ≥ 50% & Encounter enemy', 'Score Gap > 20 & Encounter enemy', 'Score Gap < 20 & Encounter enemy'],
            action: ['Runaway','Move towards coins','Move towards coins']
        }
    ]
    const showModal = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const addAttribute = (type: string) => {
        clickMusic()
        if (type === 'attack' && detail.gameLifeEntityInfo.coinPower > 0) {
            setDetail(prevState => {
                const newGameLifeEntityInfo = {
                    ...prevState.gameLifeEntityInfo,
                    attack: prevState.gameLifeEntityInfo.attack + 1,
                    coinPower: prevState.gameLifeEntityInfo.coinPower - 1
                };

                return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
            });
        } else if (type === 'viewRange' && detail.gameLifeEntityInfo.coinPower > 1) {
            setDetail(prevState => {
                const newGameLifeEntityInfo = {
                    ...prevState.gameLifeEntityInfo,
                    viewRange: prevState.gameLifeEntityInfo.viewRange + 2,
                    coinPower: prevState.gameLifeEntityInfo.coinPower - 2
                };
                return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
            });
        }
    }
    const subtractAttribute = (type: string) => {
        clickMusic()
        if (type === 'attack' && detail.gameLifeEntityInfo.attack > 5) {
            setDetail(prevState => {
                const newGameLifeEntityInfo = {
                    ...prevState.gameLifeEntityInfo,
                    attack: prevState.gameLifeEntityInfo.attack - 1,
                    coinPower: prevState.gameLifeEntityInfo.coinPower + 1
                };

                return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
            });
        } else if (type === 'viewRange' && detail.gameLifeEntityInfo.viewRange > 6) {
            setDetail(prevState => {
                const newGameLifeEntityInfo = {
                    ...prevState.gameLifeEntityInfo,
                    viewRange: prevState.gameLifeEntityInfo.viewRange - 2,
                    coinPower: prevState.gameLifeEntityInfo.coinPower + 2
                };
                return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
            });
        }
    }
    function checkArray(strings: any) {
        return strings.some((str: any) => str.length === 3 && !str.includes(' '));
    }
    const { isSuccess, write, isLoading } = useContractWrite({
        ...wagmigotchiContract,
        functionName: 'saveLifeInfo'
    })
    const startGameApi = async () => {
        const formData = {
            ethAddress: address,
            attack: detail.gameLifeEntityInfo.attack,
            viewRange: detail.gameLifeEntityInfo.viewRange,
            tokenId: detail.tokenId,
            moveRule: options.join(','),
            type: 0,
            gameType: 'pvp'
        }
        User.updateAttributePolicy(formData).then((res: any) => {
            if (res.code === 200) {
                navigator(`/games-lobby/${lifeDetail.tokenId}`);
            }
        })
    }
    useEffect(() => {
        setDetail(lifeDetail)
        setGameLifeEntityInfo(lifeDetail.gameLifeEntityInfo)
    }, [lifeDetail])
    useEffect(() => {
        if (isLoading) {
            countdown(60)
        } else if (!isSuccess) {
            countdown(0)
        }
    }, [isLoading]);
    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                startGame()
            }, 2000)
        }
    }, [isSuccess]);
    const startGame = () => {
        const formData = {
            ethAddress: address,
            attack: detail.gameLifeEntityInfo.attack,
            viewRange: detail.gameLifeEntityInfo.viewRange,
            tokenId: detail.tokenId,
            moveRule: options.join(','),
            type: 0,
            gameType: 'pvp'
        }
        const gameFormData = {
            ethAddress: address,
            tokenId: detail.tokenId,
            gameLevel: level
        }
        User.updateAttributePolicy(formData).then((res: any) => {
            if (res.code === 200) {
                User.startGame(gameFormData).then((res: any) => {
                    if (res.code === 200) {
                        navigator(`/loading/${detail.tokenId}`)
                    }
                })
            }
        })
    }
    const optionChange= (type: string, index: number, value: string) => {
        if (type === 'condition') {
            const newOptions = [...options]
            if (value === 'G') {
                newOptions[index] = value+'&'+ '4'
            } else {
                newOptions[index] = value+'&'+ '1'
            }
            // if (newOptions[index]) {
            //     newOptions[index] = value+'&'+newOptions[index][2]
            // } else {
            //     if (value === 'G') {
            //         console.log(value);
            //         newOptions[index] = value+'&'+ '4'
            //     } else {
            //         newOptions[index] = value+'&'+ '1'
            //     }
            // }
            setOptions(newOptions)
            console.log(newOptions);
        } else {
            const newOptions = [...options]
            if (newOptions[index]) {
                newOptions[index] = newOptions[index][0]+'&'+value
            } else {
                newOptions[index] = ' '+'&'+value
            }
            setOptions(newOptions)
            console.log(newOptions);
        }
    }
    const chooseRecommend = () => {
        setDetail(prevState => {
            const newGameLifeEntityInfo = {
                ...prevState.gameLifeEntityInfo,
                viewRange: (recommendLists[recommendValue].attributes[0]) * 2,
                attack: (recommendLists[recommendValue].attributes[1]),
                moveRule: actionsValues[recommendValue].join(','),
                coinPower: (lifeDetail.gameLifeEntityInfo.coinPower + lifeDetail.gameLifeEntityInfo.attack + lifeDetail.gameLifeEntityInfo.viewRange) - ((recommendLists[recommendValue].attributes[0]) * 2 + (recommendLists[recommendValue].attributes[1])),
            };
            return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
        });
        setOptions(actionsValues[recommendValue])
        handleCancel()
    }
    const resetPoint = () => {
        clickMusic()
        setDetail(prevState => {
            const newGameLifeEntityInfo = {
                ...prevState.gameLifeEntityInfo,
                viewRange: 6,
                attack: 5,
                coinPower: lifeDetail.gameLifeEntityInfo.coinPower+(lifeDetail.gameLifeEntityInfo.attack-5)+((lifeDetail.gameLifeEntityInfo.viewRange-6)),
            };
            return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
        });
    }
    useEffect(() => {
        setDetail(lifeDetail)
    }, [lifeDetail])
    useEffect(() => {
        if (!lifeDetail.gameLifeEntityInfo.moveRule) {
            // setOptions(['','',''])
        }
    }, []);
    const handleOnDragEnd = ((result: any) => {
        console.log(result);
        if (!result.destination) return;

        const itemsCopy = Array.from(options);
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);
        setOptions(itemsCopy);
    })
    const [modalType, setModalType] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)

    const openBuyModal = (type: number) => {
        clickMusic()
        setModalType(type)
        setModalVisible(true)
    }
    const buyModalCancel = () => {
        setModalVisible(false);
    };
    const { isSuccess: pointSuccess, write: pointWrite, isLoading: pointLoading } = useContractWrite({
        ...wagmigotchiContract,
        functionName: 'saveLifeInfo'
    })
    const { isSuccess: actionSuccess, write: actionWrite, isLoading: actionLoading } = useContractWrite({
        ...wagmigotchiContract,
        functionName: 'saveLifeInfo'
    })
    const {coins: userCoins, isNetwork, setCoins, setIsMask} = useGlobalState()
    const buyCoins = () => {
        console.log([parseInt(detail.tokenId), detail.gameLifeEntityInfo.viewRange, detail.gameLifeEntityInfo.attack, detail.gameLifeEntityInfo.moveRule]);
        pointWrite({args: [parseInt(detail.tokenId),detail.gameLifeEntityInfo.viewRange,detail.gameLifeEntityInfo.attack,detail.gameLifeEntityInfo.moveRule]})
    }
    const buyAction = () => {
        actionWrite({args: [parseInt(detail.tokenId),detail.gameLifeEntityInfo.viewRange,detail.gameLifeEntityInfo.attack,detail.gameLifeEntityInfo.moveRule+',']})
    }
    const switchButton = () => {
        if (isNetwork) {
            clickMusic()
            if (modalType) {
                if (userCoins < coins[options.length - 3]) {
                    message.error("Sorry, you don't have enough gold coins.")
                } else {
                    buyAction()
                }
            } else {
                if (userCoins < pointList[((gameLifeEntityInfo.viewRange) + gameLifeEntityInfo.attack + gameLifeEntityInfo.coinPower - 16)]) {
                    message.error("Sorry, you don't have enough gold coins.")
                } else {
                    buyCoins()
                }
            }
        }
    }
    const getUserCoins = () => {
        User.getUserCoins(address).then((res: any) => {
            setCoins(res.data)
        })
    }
    useEffect(() => {
        if (pointSuccess) {
            const formData = {
                ethAddress: address,
                tokenId: detail.tokenId
            }
            User.exchangePower(formData).then((res: any) => {
                if (res.code === 200) {
                    setDetail(prevState => {
                        const newGameLifeEntityInfo = {
                            ...prevState.gameLifeEntityInfo,
                            coinPower: detail.gameLifeEntityInfo.coinPower+1,
                        };
                        return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
                    });
                    saveAttributePolicy()
                    setModalVisible(false)
                    getUserCoins()
                }
            })
        }
    }, [pointSuccess]);
    useEffect(() => {
        if (actionSuccess) {
            setDetail(prevState => {
                const newGameLifeEntityInfo = {
                    ...prevState.gameLifeEntityInfo,
                    moveRule: detail.gameLifeEntityInfo.moveRule+',',
                };
                return { ...prevState, gameLifeEntityInfo: newGameLifeEntityInfo };
            });
            const moveRule = detail.gameLifeEntityInfo.moveRule+','
            setOptions(moveRule.split(','))
            saveAttributePolicy(moveRule)
            setModalVisible(false)
        }
    }, [actionSuccess]);
    useEffect(() => {
        if (actionLoading) {
            countdown(60)
        } else if (!actionSuccess) {
            countdown(0)
        }
    }, [actionLoading]);
    useEffect(() => {
        if (pointLoading) {
            countdown(60)
        } else if (!pointSuccess) {
            countdown(0)
        }
    }, [pointLoading]);
    const saveAttributePolicy = (moveRule?: string) => {
        const formData = {
            ethAddress: address,
            attack: detail.gameLifeEntityInfo.attack,
            viewRange: detail.gameLifeEntityInfo.viewRange,
            tokenId: detail.tokenId,
            moveRule: moveRule ? moveRule : options.join(','),
            type: 0,
            gameType: 'pvp'
        }
        const gameFormData = {
            ethAddress: address,
            tokenId: detail.tokenId,
            gameLevel: level
        }
        User.updateAttributePolicy(formData).then((res: any) => {
            if (res.code === 200) {
                getUserCoins()
                refreshData()
                countdown(0)
                // nextStep()
            }
        })
    }
    const [isGuide, setIsGuide] = useState(false)
    const [guideStep, setGuideStep] = useState(1)
    useEffect(() => {
        setIsMask(isGuide)
    }, [isGuide]);
    useEffect(() => {
        const guide = localStorage.getItem('pvpGuide')
        const guideStep = localStorage.getItem('pvpStep')
        if (guide && guideStep === '2') {
            setIsGuide(true)
            localStorage.setItem('pvpStep', '3')
        }
    }, []);
    return (
        <div className={"selection-strategy-pvp"}>
            {
                isGuide && guideStep === 1 ? <div className={"text-guide"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    'Choose the Life you want to deploy here. The higher its health, the greater the chances of winning.'
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setGuideStep(2)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            {
                isGuide && guideStep === 2 ? <div className={"text-guide text-guide2"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    'Here, you can set the action strategy for your Life to help it explore the map successfully. Click on the "+" to acquire more strategy slots.'
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setGuideStep(3)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            {
                isGuide && guideStep === 3 ? <div className={"text-guide text-guide3"}>
                        <div className={"text-container"}>
                            <Typed
                                strings={[
                                    'Here, you can view some preset action strategies.'
                                ]}
                                typeSpeed={1}      // 打字速度
                                backSpeed={50}      // 回退速度
                            />
                            <div onClick={() => setIsGuide(false)} className={"button-text-primary"}>
                                <div className={"box"}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <div className={"strategy-left-container"}>
                <Popover title={"You can increase the number of disposable skill points by spending gold coins."}>
                    <div
                        className={isGuide && guideStep === 1 ? "light-block block-detail priority" : 'light-block block-detail'}>{detail.gameLifeEntityInfo.coinPower}</div>
                </Popover>
                {
                    detail.gameLifeEntityInfo.coinPower >= lifeDetail.gameLifeEntityInfo.coinPower + (lifeDetail.gameLifeEntityInfo.attack - 5) + ((lifeDetail.gameLifeEntityInfo.viewRange - 6)) ? '' :
                        <div onClick={resetPoint}
                             className={isGuide && guideStep === 1 ? 'reset priority' : 'reset'}></div>
                }
                <div className={isGuide && guideStep === 1 ? "block-back priority" : 'block-back'}>
                    <div className="block-header">
                        2 <img src={getSrc('setout/smail-light.png')} alt=""/>
                        / + 1</div>
                    <div className={"view-block block-detail block-function"}>
                        <Popover title={'Each "Life" starts with 3 "Vision" tiles, and every 2 skill points increase the Vision by 1 tile.'}>
                            <div className="hot-point">
                            </div>
                        </Popover>
                        <span>{(detail.gameLifeEntityInfo.viewRange / 2)}</span>
                        <div className={"add-block"} onClick={() => addAttribute('viewRange')}></div>
                        <div className={"add-block subtract-block"} onClick={() => subtractAttribute('viewRange')}></div>
                    </div>
                </div>
                <div
                    className={isGuide && guideStep === 1 ? 'block-back attack-back priority' : 'block-back attack-back'}>
                    <div className="block-header">
                        1 <img src={getSrc('setout/smail-light.png')} alt=""/>
                        / + 1</div>
                    <div className={"attack-block block-detail block-function"}>
                        <Popover title={'Each "Life" starts with 5 points of "Attack Power," and every 1 skill point can increase the Attack Power by 1 point.'}>
                            <div className="hot-point">
                            </div>
                        </Popover>
                        <span>
                                {detail.gameLifeEntityInfo.attack}
                            </span>
                        <div className={"add-block"} onClick={() => addAttribute('attack')}></div>
                        <div className={"add-block subtract-block"} onClick={() => subtractAttribute('attack')}></div>
                    </div>
                </div>
                {/*{*/}
                {/*    (gameLifeEntityInfo.viewRange + gameLifeEntityInfo.attack + gameLifeEntityInfo.coinPower ) >= 26 ? '' : <div onClick={() => openBuyModal(0)} className="add-point">{pointList[((gameLifeEntityInfo.viewRange) + gameLifeEntityInfo.attack + gameLifeEntityInfo.coinPower - 16)]}*/}
                {/*        <img src={getSrc('game/gold.png')} alt=""/> / +1</div>*/}
                {/*}*/}
                {
                    (gameLifeEntityInfo.viewRange + gameLifeEntityInfo.attack + gameLifeEntityInfo.coinPower ) >= 26 ? '' :
                        <Popover placement="bottom" title={pointList[((gameLifeEntityInfo.viewRange) + gameLifeEntityInfo.attack + gameLifeEntityInfo.coinPower - 16)]+'coins / + 1'}>
                            <div onClick={() => openBuyModal(0)}
                                 className={isGuide && guideStep === 1 ? 'add-block add-block-new priority' : 'add-block add-block-new'}></div>
                        </Popover>
                }
                <img className={"cover-img"} src={detail.image} alt=""/>
                {/*<div className={"light-box"}></div>*/}
                <div className="boold-container">
                    <div className="boold-data-container">
                        <div className="data">{detail.health}</div>
                        <div className="progress-box">
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={isGuide && guideStep === 2 ? 'strategy-right-container priority' : 'strategy-right-container'}>
                <div className={"title-container"}>
                    <Popover title={'Action strategies are pre-set plans for your "life" in the map.'}>
                        <div className={isGuide && guideStep === 3 ? 'label priority' : 'label'}>
                            *Action Strategy
                        </div>
                    </Popover>
                    <div className={isGuide && guideStep === 3 ? 'book active-book priority' : 'book'}
                         onClick={showModal}></div>
                </div>
                <div className={"table-container"}>
                    <Popover title={'The system will execute the strategies in the order you have arranged them.'}>
                        <div className="label">Order</div>
                    </Popover>
                    <div className="label">Condition</div>
                    <div className="label">Action</div>
                </div>
                <div className={"option-container"}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="list">
                            {(provided: any) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {options.map((item, index) => (
                                        <Draggable key={index} draggableId={item+index} index={index}>
                                            {(provided: any) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={index}
                                                    className="option-item"
                                                >
                                                    <div className="label-index">{index + 1}</div>
                                                    <div className={"select-container"}>
                                                        <div className="select-item">
                                                            <Select
                                                                value={item[0]}
                                                                onChange={(value) => optionChange('condition', index, value)}
                                                                className={item[0] ? 'value-item' : 'value-item no-value'}
                                                                placeholder={"Make your selection"}
                                                                onFocus={clickMusic}
                                                                options={condition.map((item: any) => ({ value: item.value, label: item.label }))}
                                                            />
                                                        </div>
                                                        <div className="select-item">
                                                            <Select
                                                                value={options[index].length === 3 && options[index][0] !== 'G' ? action[options[index][0]][parseInt(options[index][2])-1].label : options[index].length === 3 ? action[options[index][0]][parseInt(options[index][2]) - 4].label : null}
                                                                className={item[0] ? 'value-item' : 'value-item no-value'}
                                                                onFocus={clickMusic}
                                                                onChange={(value) => optionChange('action', index, value)}
                                                                options={action[options[index][0]]}
                                                                placeholder={"Make your selection"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {
                        options.length < 6 ? <div onClick={() => openBuyModal(1)} className="add-select">Unlock for {coins[options.length-3]}
                            <img src={getSrc('game/gold.png')} alt=""/> +1</div> : ''
                    }
                    {/*{options.map((item, index) => (*/}
                    {/*    <div key={index} className="option-item">*/}
                    {/*        <img src={getSrc('setout/move-img.png')} alt=""/>*/}
                    {/*        <div className="label-index">{index + 1}</div>*/}
                    {/*        <div className={"select-container"}>*/}
                    {/*            <div className="select-item">*/}
                    {/*                <Select*/}
                    {/*                    value={item[0]}*/}
                    {/*                    onChange={(value) => optionChange('condition', index, value)}*/}
                    {/*                    placeholder={"Make your selection"}*/}
                    {/*                    options={[*/}
                    {/*                        {value: 'A', label: 'My Health ≥ 50% & Encounter enemy'},*/}
                    {/*                        {value: 'B', label: 'My Health < 50% & Encounter enemy'},*/}
                    {/*                        {value: 'C', label: 'Score Gap > 20 & Encounter enemy'},*/}
                    {/*                        {value: 'D', label: 'Score Gap < 20 & Encounter enemy'},*/}
                    {/*                    ]}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div className="select-item">*/}
                    {/*                <Select*/}
                    {/*                    value={item[2]}*/}
                    {/*                    onChange={(value) => optionChange('action', index, value)}*/}
                    {/*                    placeholder={"Make your selection"}*/}
                    {/*                    options={[*/}
                    {/*                        {value: '1', label: 'Runaway'},*/}
                    {/*                        {value: '2', label: 'Move towards coins'},*/}
                    {/*                        {value: '3', label: 'Attack'},*/}
                    {/*                    ]}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </div>
                <div className="button-container">
                    <Button disabled={!checkArray(options)} isLoading={count !== 0}>
                        <div onClick={startGameApi}>Choose Battle</div>
                    </Button>
                </div>
                <Modal
                    open={open}
                    title="To recommend"
                    onCancel={handleCancel}
                    width={1272}
                    footer={[
                        <Button key="back">
                            <div className={"back"} onClick={handleCancel}>
                                Back
                            </div>
                        </Button>,
                        <Button key="Confirm">
                            <div onClick={chooseRecommend}>
                                Confirm
                                <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                            </div>
                        </Button>
                    ]}
                >
                    <div className="recommend-container">
                        {
                            recommendLists.map((item, index) => (
                                <div className="recommend-item" key={index}>
                                    <div className={"title"}>
                                        {item.title}
                                    </div>
                                    <div className={recommendValue === index ? "recommend-list-active recommend-list" : "recommend-list"}>
                                        <div className="list-item">
                                            <div className="icon">
                                                {item.attributes.map((attribute, index) => (
                                                    <div key={index} className="icon-cover">
                                                        {attribute}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="description">
                                                {item.condition.map((description, index) => (
                                                    <div key={index} className="description-item">
                                                        {description}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="type">
                                                {item.action.map((type, index) => (
                                                    <div key={index} className="type-item">
                                                        {type}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <img onClick={() => setRecommend(index)} className="check-box"
                                             src={getSrc(`setout/check-${recommendValue === index ? 'active' : 'no'}.png`)}
                                             alt=""/>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Modal>
                <Modal
                    open={modalVisible}
                    className={"buy-modal"}
                    onCancel={buyModalCancel}
                    width={1000}
                    getContainer={false}
                    footer={[
                        <Button key="back">
                            <div className={"back"} onClick={buyModalCancel}>
                                Back
                            </div>
                        </Button>,
                        <Button isLoading={count !== 0} key="Confirm">
                            <div onClick={switchButton}>
                                Confirm
                                <img className="arrow-icon" src={getSrc('components/button-arrow.png')} alt=""/>
                            </div>
                        </Button>
                    ]}
                >
                    <div className="buy-modal">
                        <div className={"title"}>
                            {modalType ? `Are you sure you want to spend ${coins[options.length-3]} gold coins to unlock the next action strategy for the life with Token ID: ${detail.tokenId}?` : `Are you sure you want to spend ${pointList[((gameLifeEntityInfo.viewRange) + gameLifeEntityInfo.attack + gameLifeEntityInfo.coinPower - 16)]} gold coins to add a skill point for the life with Token ID: ${detail.tokenId}?`}
                        </div>
                        <div className="cover-box">
                            <img src={detail.image} alt=""/>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}