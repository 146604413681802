import * as React from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import { Layout } from "../views/layout";
import PveHome from "../views/pve"
import { PveMap } from "../views/pve/new-index";
import {PveSetout} from "../views/setout"
import { Edit } from '../views/edit';
import { Game } from "../views/game";
import { LoadingPage } from '../views/loading'
import { Welcome } from '../views/welcome';
import { Rank } from '../views/rank';
import { CheckPage } from "../views/check"
import { ProtectedRoute } from "./ProtectedRoute"
import { HomePage } from "../views/home";
import { PvpSetout } from "../views/pvp"
import { GamesLobby } from "../views/lobby";
import { CreateLobby } from "../views/lobby/createLobby"
import { GamePVP } from "../views/pvp/game"
import { NewGamePVE } from "../views/game/game"
import {WebSite} from "../views/website";
import {Leaderboard} from "../views/rank/leaderboard";

const RedirectToHome = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const name = queryString.get('name');
        if (name) {
            navigate(`/welcome?name=${name}`)
        } else {
            navigate('/welcome');
        }
    }, [navigate]);

    return null;
};
const GetRouters = () => {
    return useRoutes([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: '/',
                    element: <ProtectedRoute><RedirectToHome/></ProtectedRoute>,
                },
                {
                    path: "home",
                    element: <ProtectedRoute><HomePage/></ProtectedRoute>
                },
                {
                    path: "pve",
                    element: <ProtectedRoute><PveMap/></ProtectedRoute>
                },
                {
                    path: "games-lobby/:tokenId",
                    element: <ProtectedRoute><GamesLobby/></ProtectedRoute>
                },
                {
                    path: "create-lobby/:tokenId",
                    element: <ProtectedRoute><CreateLobby/></ProtectedRoute>
                },
                {
                    path: "pve-setout/:level",
                    element: <ProtectedRoute><PveSetout/></ProtectedRoute>
                },
                {
                    path: "pvp-setout",
                    element: <ProtectedRoute><PvpSetout/></ProtectedRoute>
                },
                {
                    path: "edit",
                    element: <ProtectedRoute><Edit/></ProtectedRoute>
                },
                {
                    path: "game-pve/:tokenId",
                    element: <ProtectedRoute><NewGamePVE/></ProtectedRoute>
                },
                {
                    path: "game-pvp/:tokenId",
                    element: <ProtectedRoute><GamePVP/></ProtectedRoute>
                },
                {
                    path: "loading/:tokenId",
                        element: <ProtectedRoute><LoadingPage/></ProtectedRoute>
                },
                {
                    path: 'welcome',
                    element: <Welcome/>
                },
                {
                    path: 'leaderboard',
                        element: <ProtectedRoute><Rank/></ProtectedRoute>
                },
                {
                    path: 'check',
                    element: <CheckPage/>
                },
                {
                    path: 'website',
                    element: <WebSite/>
                },
                {
                    path: 'tournamant',
                    element: <ProtectedRoute><Leaderboard/></ProtectedRoute>
                }
            ]
        }
    ])
}
export default GetRouters