import "./index.scss"
import {getSrc} from "../../utils/utils";
import ActivitiesButton from "../../components/Activities-Button"
import {useNavigate} from "react-router-dom";
import { useAccount } from "wagmi";
import { User } from "../../api"
import {useEffect, useState} from "react";
import { useGlobalState } from "../../hook/useGlobalState";
import {Helmet} from "react-helmet";

export function CheckPage() {
    const navigate  = useNavigate();
    const { address } = useAccount()
    const goBack = () => {
        navigate(-1);
    }
    const openUrl = ((url: string) => {
        window.open(url)
    })
    const { isWhiteList, setIsWhiteList } = useGlobalState();
    const startGame = (() => {
        navigate('/pve')
    })
    return (
        <div className={"check-container"}>
            <Helmet><title>Start</title></Helmet>
            <div className={"arrow-container"}>
                <img onClick={goBack} src={getSrc('setout/arrow-left.png')} alt=""/>
            </div>
            {
                isWhiteList ? '' :
                    <div className={"header-button"}>
                        <ActivitiesButton onClick={() => openUrl('https://forms.gle/mjHPPbh1wmLRZsiN9')}>Fill out the internal beta for application portal</ActivitiesButton>
                    </div>
            }
            <div className={"rules-modal"}>
                <img className={"logo"} src={getSrc('check/avatar.png')} alt=""/>
                <div className={"title"}>
                    Reward
                </div>
                <div className={"content-item"}>
                    1、Please follow us on Twitter: @cellulalifegame.
                </div>
                <div className={"content-item"}>
                    2、We will announce the list of winners on Twitter and Discord every Friday at <span className={"bold"}>10:00 PM (SGT).</span> Please stay tuned for updates.</div>
                <div className={"content-item"}>
                    3、 A total of <span className={"bold"}>3 million</span> coins will be awarded at this event, distributed in three installments of 1 million coins each.
                </div>
                <div className={"content-item"}>
                    4、 Each installment of coins will be divided among the <span className={"bold"}>top 30</span> players on the game leaderboard.
                </div>
                <div className={"content-item"}>
                    5、 Rewards will be distributed to your account within <span className={"bold"}>24 hours</span> after the announcement of the winners.
                </div>
                {
                    isWhiteList ?
                        <div className={"button-container"}>
                            <ActivitiesButton onClick={() => startGame()}>
                                Start Game
                            </ActivitiesButton>
                        </div>
                        : ''
                }
                <div className={"title title2"}>
                    Activity Description
                </div>
                <div className={"description-item"}>
                    {'Aw Pac-Man is an adventurous game where you\'ll play as a brave explorer, using your carefully crafted "life" to boldly explore various maze maps. Your goal is to collect coins and achieve this objective by setting exploration strategies and adjusting skill values for your "life." Each time you enter a maze, your "life" will be randomly teleported to a location. You can observe your "life\'s" movements and coin collection on the game page, and make adjustments to your exploration strategies as needed to help your "life" successfully complete levels.'}
                </div>
                <div className={"description-item"}>
                    {'However, the maze is inhabited by ghostly creatures who guard its resources. They will search for and chase down your "life" once it enters the maze. Some of these ghosts are incredibly powerful, requiring clever evasion strategies to escape their pursuit. On the other hand, there are weaker ghosts that you can eliminate through offensive tactics to obtain the coins they carry.'}
                </div>
                <div className={"description-item"}>
                    {"Let's push forward together, fellow adventurers! Embrace this thrilling adventure challenge and give it your all!"}
                </div>
            </div>
        </div>
    )
}