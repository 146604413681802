export const networkList = [
    {
        id: 420,
        name: 'Optimism Goerli',
        network: 'Optimism Goerli',
        nativeCurrency: {
            decimals: 18,
            name: 'Goerli Ether',
            symbol: 'ETH',
        },
        rpcUrls: {
            public: {http: ['https://optimism-goerli.blockpi.network/v1/rpc/public']},
            default: {http: ['https://optimism-goerli.blockpi.network/v1/rpc/public']},
        },
        blockExplorers: {
            etherscan: {name: 'Etherscan', url: 'https://goerli-optimism.etherscan.io'},
            default: {name: 'Etherscan', url: 'https://goerli-optimism.etherscan.io'},
        }
    },
    {
        id: 17001,
        name: 'Redstone Holesky',
        network: 'Redstone Holesky',
        nativeCurrency: {
            decimals: 18,
            name: 'ETH',
            symbol: 'ETH',
        },
        rpcUrls: {
            public: {http: ['https://rpc.holesky.redstone.xyz']},
            default: {http: ['https://rpc.holesky.redstone.xyz']},
        },
        blockExplorers: {
            etherscan: {name: 'Etherscan', url: 'https://explorer.holesky.redstone.xyz'},
            default: {name: 'Etherscan', url: 'https://explorer.holesky.redstone.xyz'},
        }
    },
    {
        id: 5611,
        name: 'opBNB Testnet',
        network: 'opBNB Testnet',
        nativeCurrency: {
            decimals: 18,
            name: 'tBNB',
            symbol: 'tBNB',
        },
        rpcUrls: {
            public: {http: ['https://opbnb-testnet-rpc.bnbchain.org']},
            default: {http: ['https://opbnb-testnet-rpc.bnbchain.org']},
        },
        blockExplorers: {
            etherscan: {name: 'BscScan', url: 'https://opbnb-testnet.bscscan.com'},
            default: {name: 'BscScan', url: 'https://opbnb-testnet.bscscan.com'},
        }
    },
    {
        id: 11822,
        name: 'Artela Testnet',
        network: 'Artela Testnet',
        nativeCurrency: {
            decimals: 18,
            name: 'ART',
            symbol: 'ART',
        },
        rpcUrls: {
            public: {http: ['https://betanet-rpc1.artela.network/']},
            default: {http: ['https://betanet-rpc1.artela.network/']},
        },
        blockExplorers: {
            etherscan: {name: 'BscScan', url: 'https://betanet-scan.artela.network/'},
            default: {name: 'BscScan', url: 'https://betanet-scan.artela.network/'},
        }
    }
]